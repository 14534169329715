import {
  each,
  entries,
  filter,
  go,
  head,
  map,
  pick,
  pipe,
  reject,
  sel,
  range,
  sortByDesc,
  sortBy,
  take,
  ippL,
  find,
  some,
  partition,
} from 'fxjs/es';
import { BpOptionConstantS } from '../../../../BpOption/S/Constant/module/BpOptionConstantS.js';
import { getCurrentFcanvas, getCvDesigns, getCvObj } from '../../../../Maker/F/Fcanvas/cv_object.js';
import { minusStrokeWidth } from '../../../../Maker/F/Fcanvas/stroke.js';
import { getFcanvass } from '../../../../Maker/F/getSth.js';
import { NewMakerFcanvasFuncF } from '../../../Fcanvas/Func/F/Function/module/NewMakerFcanvasFuncF.js';
import { NewMakerUtilS } from '../../../Util/S/Function/module/NewMakerUtilS.js';
import { addCvPreview, removeCvPreview } from '../../../../Maker/F/CvPreview/cv_object.js';
import { CLONE_OBJ_LENGTH } from '../../S/Constant/maskingtape.js';
import { NewMakerFitPrintWidthF } from '../../../FitPrintWidth/F/Function/module/NewMakerFitPrintWidthF.js';
import { getFwidth } from '../../../../Maker/F/util.js';
//CloneHorizontalCvObj
//_data.clone_parent_cid = true
function getMaxWidth(cv_print_area) {
  return go(cv_print_area, minusStrokeWidth, sel('width'));
}
export const insertCloneHorizontalCvObj = (cv_obj) => {
  const fcanvas = cv_obj.canvas;
  if (fcanvas?.fcanvas_data?.maker_type !== BpOptionConstantS.MASKING_TAPE_EDITOR) return;
  const cid = cv_obj.cid;
  const cv_obj_idx = fcanvas._objects.indexOf(cv_obj);
  const cv_print_area = getCvObj(fcanvas._objects, 'cv_print_area');
  const width = getMaxWidth(cv_print_area);
  go(
    range(CLONE_OBJ_LENGTH),
    map((idx) => {
      const clone_cv_obj_json_left = cv_obj.toJSON();
      clone_cv_obj_json_left.cid = _p.uniqueId('c');
      clone_cv_obj_json_left._data.clone_parent_cid = cid;
      clone_cv_obj_json_left.left = clone_cv_obj_json_left.left - width * (idx + 1);
      clone_cv_obj_json_left.selectable = false;
      clone_cv_obj_json_left.evented = false;
      return clone_cv_obj_json_left;
    }),
    map((clone_cv_obj) => NewMakerFcanvasFuncF.shallowClone(cv_obj, clone_cv_obj)),
    ippL,
    each(([idx, clone_cv_obj]) => {
      fcanvas.insertAt(clone_cv_obj, cv_obj_idx + (idx + 1));
    }),
  );
  go(
    range(CLONE_OBJ_LENGTH),
    map((idx) => {
      const clone_cv_obj_json_left = cv_obj.toJSON();
      clone_cv_obj_json_left.cid = _p.uniqueId('c');
      clone_cv_obj_json_left._data.clone_parent_cid = cid;
      clone_cv_obj_json_left.left = clone_cv_obj_json_left.left + width * (idx + 1);
      clone_cv_obj_json_left.selectable = false;
      clone_cv_obj_json_left.evented = false;
      return clone_cv_obj_json_left;
    }),
    map((clone_cv_obj) => NewMakerFcanvasFuncF.shallowClone(cv_obj, clone_cv_obj)),
    ippL,
    each(([idx, clone_cv_obj]) => {
      fcanvas.insertAt(clone_cv_obj, cv_obj_idx);
    }),
  );
};

export const setCloneHorizontalCvObjLocation = (cv_obj, fcanvas) => {
  if (!fcanvas) return;
  const cv_print_area = getCvObj(fcanvas._objects, 'cv_print_area');
  const width = getMaxWidth(cv_print_area);
  const [left_cv_objs, right_cv_objs] = go(
    getCloneHorizontalCvObj(cv_obj, fcanvas),
    partition((hori_cv_obj) => hori_cv_obj.left < cv_obj.left),
  );
  go(
    left_cv_objs,
    ippL,
    each(([idx, _cv_obj]) => {
      _cv_obj.top = cv_obj.top;
      _cv_obj.flipX = cv_obj.flipX;
      _cv_obj.flipY = cv_obj.flipY;
      _cv_obj.left = cv_obj.left - width * (idx + 1);
    }),
  );
  go(
    right_cv_objs,
    ippL,
    each(([idx, _cv_obj]) => {
      _cv_obj.top = cv_obj.top;
      _cv_obj.flipX = cv_obj.flipX;
      _cv_obj.flipY = cv_obj.flipY;
      _cv_obj.left = cv_obj.left + width * (idx + 1);
    }),
  );
};

export const removeCloneHorizontalCvObj = (cv_obj) => {
  if (cv_obj?.canvas?.fcanvas_data?.maker_type !== BpOptionConstantS.MASKING_TAPE_EDITOR) return;
  const chcos = getCloneHorizontalCvObj(cv_obj);
  if (chcos.length) {
    go(
      [...chcos],
      each((cv_obj) => {
        NewMakerFcanvasFuncF.removeCvObj(cv_obj);
      }),
    );
  }
};

export const removeAllClonHorizntalCvObj = () => {
  go(
    getFcanvass(),
    each(
      pipe(
        getCvDesigns,
        filter((cv_obj) => cv_obj._data.clone_parent_cid),
        each(NewMakerFcanvasFuncF.removeCvObj),
      ),
    ),
  );
};

export const hasWrongFittedObj = (product_faces2) => {
  return go(
    product_faces2,
    some((pf) => {
      const { width } = go(pf.cv_print_area, minusStrokeWidth);
      return go(
        pf.designs,
        some((cv_obj) => {
          if (cv_obj._data?.is_fitted_to_print_width) {
            return width !== Math.round(getFwidth(cv_obj));
          }
        }),
      );
    }),
  );
};

export const resetCloneHorizontalCvObj = async () => {
  const fcanvas = getCurrentFcanvas();
  if (fcanvas?.fcanvas_data?.maker_type !== BpOptionConstantS.MASKING_TAPE_EDITOR) return;
  removeCvPreview(fcanvas, true, true);
  go(
    fcanvas._objects,
    getCvDesigns,
    reject((cv_obj) => cv_obj._data.clone_parent_cid),
    (parent_cv_objs) => {
      fcanvas.remove.apply(fcanvas, parent_cv_objs);
      fcanvas.add.apply(
        fcanvas,
        go(
          parent_cv_objs,
          each(
            (cv_obj) =>
              cv_obj._data.is_fitted_to_print_width &&
              NewMakerFitPrintWidthF.activateFitToPrintWidth(cv_obj, fcanvas),
          ),
        ),
      );
    },
  );
  await addCvPreview(fcanvas);
};
export function getCloneHorizontalCvObj(cv_obj, _fcanvas) {
  const fcanvas = cv_obj.canvas || _fcanvas;
  return go(
    fcanvas._objects,
    filter((co) => co._data.clone_parent_cid === cv_obj.cid),
  );
}

export function isGoodHorizontalStatus({ cv_objs, cv_print_area }) {
  try {
    const cv_obj = go(
      cv_objs,
      find((cv_obj) => !cv_obj._data.clone_parent_cid),
    );
    const left_sorted_cv_objs = go(
      cv_objs,
      filter((co) => co._data.clone_parent_cid === cv_obj.cid),
      (cv_objs) => cv_objs.concat(cv_obj),
      sortBy((cv_obj) => cv_obj.left),
    );
    const max_width = getMaxWidth(cv_print_area);
    return Math.abs(Math.abs(left_sorted_cv_objs[1].left - left_sorted_cv_objs[0].left) - max_width) < 10;
  } catch (e) {
    return false;
  }
}

export const moveScaleCloneHorizontalCvObj = (cv_obj) => {
  const fcanvas = cv_obj.canvas;
  if (fcanvas?.fcanvas_data?.maker_type !== BpOptionConstantS.MASKING_TAPE_EDITOR) return;
  const cv_print_area = getCvObj(fcanvas._objects, 'cv_print_area');
  const max_width = getMaxWidth(cv_print_area);
  if (cv_obj._objects && cv_obj._data?.cv_type !== 'cv_group') {
    const center = cv_obj.getCenterPoint();
    go(
      cv_obj._objects,
      each((group_item) => {
        const top = group_item.top * cv_obj.scaleX + center.y;
        const left = group_item.left * cv_obj.scaleX + center.x;
        const scale = group_item.scaleX * cv_obj.scaleX;
        _moveScale(group_item, getCloneHorizontalCvObj(group_item), { top, left, scale, max_width });
      }),
    );
  }
  const clone_cv_objs = getCloneHorizontalCvObj(cv_obj);
  if (clone_cv_objs.length) {
    const fcanvas = cv_obj.canvas;
    const cv_print_area = getCvObj(fcanvas._objects, 'cv_print_area');
    const max_width = getMaxWidth(cv_print_area);
    _moveScale(cv_obj, clone_cv_objs, {
      top: cv_obj.top,
      left: cv_obj.left,
      scale: cv_obj.scaleX,
      max_width,
    });
  }
};

function _moveScale(cv_obj, clone_cv_objs, { top, left, max_width, scale }) {
  go(
    getLeftClone(clone_cv_objs),
    ippL,
    each(([i, clone_cv_obj]) => {
      clone_cv_obj.set({
        top,
        flipX: cv_obj.flipX,
        flipY: cv_obj.flipY,
        left: left - max_width * (i + 1),
        scaleX: scale,
        scaleY: scale,
      });
    }),
  );
  go(
    getRightClone(clone_cv_objs),
    ippL,
    each(([i, clone_cv_obj]) => {
      clone_cv_obj.set({
        top,
        flipX: cv_obj.flipX,
        flipY: cv_obj.flipY,
        left: left + max_width * (i + 1),
        scaleX: scale,
        scaleY: scale,
      });
    }),
  );
}

function getLeftClone(cv_objs) {
  return go(
    cv_objs,
    sortBy((cv_obj) => cv_obj.left),
    take(CLONE_OBJ_LENGTH),
    (cv_obj) => cv_obj.reverse(),
  );
}
function getRightClone(cv_objs) {
  return go(
    cv_objs,
    sortByDesc((cv_obj) => cv_obj.left),
    take(CLONE_OBJ_LENGTH),
    (cv_obj) => cv_obj.reverse(),
  );
}

function locateCvObjFromCirclePoint(coords, circle_point, angle) {
  const tl = {
    x: coords.tl.x,
    y: -coords.tl.y,
  };
  const tr = {
    x: coords.tr.x,
    y: -coords.tr.y,
  };
  const { x: left, y: top } = NewMakerUtilS.locateXYInCircle(tl, circle_point, angle);
  const { x: tr_x, y: tr_y } = NewMakerUtilS.locateXYInCircle(tr, circle_point, angle);
  const deg = NewMakerUtilS.getFDegree(tr_x - left, top - tr_y);
  return { top, left, deg };
}
export const rotateCloneHorizontalCvObj = (cv_obj) => {
  if (cv_obj?.canvas?.fcanvas_data?.maker_type !== BpOptionConstantS.MASKING_TAPE_EDITOR) return;
  const chcos = getCloneHorizontalCvObj(cv_obj);
  if (cv_obj._objects && cv_obj._data?.cv_type !== 'cv_group') {
    const fcanvas = cv_obj.canvas;
    const cv_print_area = getCvObj(fcanvas._objects, 'cv_print_area');
    const max_width = getMaxWidth(cv_print_area);
    go(
      cv_obj._objects,
      each((group_item) => {
        const clone_cv_objs = getCloneHorizontalCvObj(group_item).reverse();
        go(
          getLeftClone(clone_cv_objs),
          ippL,
          each(([idx, chco]) => {
            const circle_point = cv_obj.getCenterPoint();
            circle_point.x = circle_point.x - max_width * (idx + 1);
            const {
              top,
              left,
              deg: angle,
            } = locateCvObjFromCirclePoint(group_item.oCoords, circle_point, cv_obj.angle);
            chco.set({ top, left, angle });
            chco.setCoords();
          }),
        );
        go(
          getRightClone(clone_cv_objs),
          ippL,
          each(([idx, chco]) => {
            const circle_point = cv_obj.getCenterPoint();
            circle_point.x = circle_point.x + max_width * (idx + 1);
            const {
              top,
              left,
              deg: angle,
            } = locateCvObjFromCirclePoint(group_item.oCoords, circle_point, cv_obj.angle);
            chco.set({ top, left, angle });
            chco.setCoords();
          }),
        );
      }),
    );
  }
  if (chcos.length) {
    const angle = cv_obj.angle;
    go(
      getCloneHorizontalCvObj(cv_obj),
      each((chco) => {
        chco.setAngle(angle);
        chco.setCoords();
      }),
    );
  }
};

function getXYToMoveFromRect({ rect, xy }) {
  const x = xy.x - rect.left;
  const x2 = rect.width - x;
  const y = xy.y - rect.top;
  const y2 = rect.height - y;
  let x_to_move, y_to_move;

  if (x < 0) x_to_move = -x;
  else if (x2 < 0) x_to_move = x2;
  if (y < 0) y_to_move = -y;
  else if (y2 < 0) y_to_move = y2;
  return {
    x: x_to_move,
    y: y_to_move,
  };
}
//tl.x
function xy1xy2Distance(x1, y1, x2, y2) {
  return Math.sqrt(Math.pow(Math.abs(x2 - x1), 2) + Math.pow(Math.abs(y2 - y1), 2));
}
export function moveActiveIntoRect(cv_obj) {
  const fcanvas = cv_obj?.canvas || G.mp.maker.editing_canvas();
  if (fcanvas?.fcanvas_data?.maker_type !== BpOptionConstantS.MASKING_TAPE_EDITOR) return;
  const target_cv_obj = G.mp.maker.active();
  if (target_cv_obj?._objects && target_cv_obj?._data?.cv_type !== 'cv_group') return;
  const cv_print_area = getCvObj(fcanvas._objects, 'cv_print_area');
  const rect = go(cv_print_area, minusStrokeWidth);
  go(
    cv_obj ? [cv_obj] : getCvDesigns(fcanvas._objects),
    reject((cv_obj) => cv_obj._data.clone_parent_cid),
    each((target_cv_obj) => {
      if (target_cv_obj?.intersectsWithObject(cv_print_area)) return;
      const four_coords = go(
        target_cv_obj.oCoords,
        pick(['tl', 'tr', 'bl', 'br']),
        entries,
        map(([k, xy]) => getXYToMoveFromRect({ rect, xy })),
        filter((xy) => xy.x || xy.y),
      );
      if (four_coords.length !== 4) return;
      const xy = go(
        four_coords,
        map((xy) => ({ x: parseInt(xy.x || 0), y: parseInt(xy.y || 0) })),
        sortByDesc((xy) => xy1xy2Distance(0, 0, xy.x, xy.y)),
        head,
      );
      if (xy) {
        target_cv_obj.is_overflow = false;
        target_cv_obj.set({ top: target_cv_obj.top + xy.y, left: target_cv_obj.left + xy.x });
        target_cv_obj.setCoords();
        moveScaleCloneHorizontalCvObj(target_cv_obj);
        fcanvas.renderAll();
        $.alert(`${T('maker_error_alert::점선 밖으로 디자인을 위치 시킬수 없습니다.')}😂`);
      }
    }),
  );
}
