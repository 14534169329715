import { getCurrentFcanvas } from '../../../Maker/F/Fcanvas/cv_object.js';
import { $setCss } from 'fxdom/es';
import { BpOptionF } from './module/BpOptionF.js';
import { NewMakerCvObjActionF } from '../../../NewMaker/CvObjAction/F/Function/module/NewMakerCvObjActionF.js';
import { makeBpcfAvgSize } from '../../../Maker/F/canvas_size.js';
import { makeFakeMpMakerSize } from '../../../Maker/F/fake_mp_maker.js';

export const afterSelection = async ({ bp_option_groups_el }) => {
  const canvas = getCurrentFcanvas().lowerCanvasEl;
  $setCss({ visibility: 'hidden' }, canvas);
  const selected_option_group = BpOptionF.makeSelectedOptionGroup(bp_option_groups_el);
  box().maker.product_color._.selected_option_group = selected_option_group;
  G.mp.maker.reset_box_data();
  await NewMakerCvObjActionF.render({ bp_option_ids: selected_option_group.bp_option_ids });
  G.mp.maker.bpcf_size = makeBpcfAvgSize();
  makeFakeMpMakerSize();
  G.mp.maker.zoom.canvas_container_display_mode(true);
  $setCss({ visibility: 'visible' }, canvas);
};
