import { go, tap } from 'fxjs/es';
import { MuiF } from '../../../../../../Mui/F/Function/module/MuiF.js';
import { VectorEditorAcrylicFigureFreeCreatorPCMuiF } from '../Mui/module/VectorEditorAcrylicFigureFreeCreatorPCMuiF.js';

export const makeAcrylicFigure = ({
  title,
  price,
  art_board: { width: art_board_width, height: art_board_height, shape_path_data: art_board_shape_path_data },
  empty_template_el,
  stand_leg: {
    ground: {
      size_delta: stand_leg_ground_size_delta,
      position_delta: stand_leg_ground_position_delta,
      min_width: stand_leg_ground_min_width,
      max_width: stand_leg_ground_max_width,
      width: stand_leg_ground_width,
      height: stand_leg_ground_height,
    },
    notch: { round: stand_leg_notch_round, width: stand_leg_notch_width, height: stand_leg_notch_height },
  },
  preProcess,
  postProcess,
}) =>
  new Promise((resolve, reject) =>
    MuiF.openFrame(VectorEditorAcrylicFigureFreeCreatorPCMuiF.frame, (frame, page, [tab]) => {
      const frame_closed = frame.closed;
      frame.closed = (frame_el, data) =>
        go(
          frame_closed.call(frame, frame_el, data),
          tap(() => resolve(data)),
        );

      const tab_appending = tab.appending;
      tab.appending = (tab_el) => {
        tab_el.__mp_title = title;
        tab_el.__mp_price = price;
        tab_el.__mp_art_board_width = art_board_width;
        tab_el.__mp_art_board_height = art_board_height;
        tab_el.__mp_art_board_shape_path_data = art_board_shape_path_data;
        tab_el.__mp_empty_template_el = empty_template_el;
        tab_el.__mp_stand_leg_ground_size_delta = stand_leg_ground_size_delta;
        tab_el.__mp_stand_leg_ground_position_delta = stand_leg_ground_position_delta;
        tab_el.__mp_stand_leg_ground_min_width = stand_leg_ground_min_width;
        tab_el.__mp_stand_leg_ground_max_width = stand_leg_ground_max_width;
        tab_el.__mp_stand_leg_ground_width = stand_leg_ground_width;
        tab_el.__mp_stand_leg_ground_height = stand_leg_ground_height;
        tab_el.__mp_stand_leg_notch_round = stand_leg_notch_round;
        tab_el.__mp_stand_leg_notch_width = stand_leg_notch_width;
        tab_el.__mp_stand_leg_notch_height = stand_leg_notch_height;
        tab_el.__mp_postProcess = postProcess;
        tab_el.__mp_preProcess = preProcess;

        return tab_appending.call(tab, tab_el);
      };
    }).catch(reject),
  );
