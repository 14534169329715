import { REDESIGN_PRODUCT_COLUMNS } from '../../Setting/S/constant.js';

export const MINIMUM_PROFIT_UNDER_5000 = 100;
export const MINIMUM_PROFIT = 1000;
export const MAXIMUM_PROFIT = 300000;

const BASE_PRODUCT_COLUMNS = ['name', 'name_en', 'name_jp', 'is_public', 'profit', 'profit_en', 'profit_jp'];
export const PRODUCT_COLUMNS = [...BASE_PRODUCT_COLUMNS, ...REDESIGN_PRODUCT_COLUMNS];

export const PRODUCT_STATUS = {
  PROHIBITED: 'prohibited',
  CHECKING: 'checking',
  PUBLIC: 'public',
  PRIVATE: 'private',
  NOT_CONFIRMED: 'not_confirmed',
  READY_CONFIRM: 'ready_confirm',
  NOT_USE_PRODUCT: 'not_use_product',
  PB_ME: 'pb_me',
};

export const PRODUCT_STATUS_TEXT = (key) =>
  ({
    [PRODUCT_STATUS.PROHIBITED]: T('mshop::ProductStatus::prohibited'),
    [PRODUCT_STATUS.CHECKING]: T('mshop::ProductStatus::checking'),
    [PRODUCT_STATUS.PUBLIC]: T('mshop::ProductStatus::public'),
    [PRODUCT_STATUS.PRIVATE]: T('mshop::ProductStatus::private'),
    [PRODUCT_STATUS.READY_CONFIRM]: T('mshop::ProductStatus::ready_confirm'),
    [PRODUCT_STATUS.NOT_CONFIRMED]: T('mshop::ProductStatus::not_confirmed'),
    [PRODUCT_STATUS.NOT_USE_PRODUCT]: T('mshop::ProductStatus::not_use_product'),
    [PRODUCT_STATUS.PB_ME]: T('mshop::ProductStatus::pb_me'),
  }[key]);

export const PRODUCT_STATUS_TOOLTIP = (key) =>
  ({
    [PRODUCT_STATUS.PROHIBITED]: T('mshop::ProductStatusTooltip::prohibited'),
    [PRODUCT_STATUS.CHECKING]: T('mshop::ProductStatusTooltip::checking'),
    [PRODUCT_STATUS.PUBLIC]: T('mshop::ProductStatusTooltip::public'),
    [PRODUCT_STATUS.PRIVATE]: T('mshop::ProductStatusTooltip::private'),
    [PRODUCT_STATUS.READY_CONFIRM]: T('mshop::ProductStatusTooltip::ready_confirm'),
    [PRODUCT_STATUS.NOT_CONFIRMED]: T('mshop::ProductStatusTooltip::not_confirmed'),
    [PRODUCT_STATUS.NOT_USE_PRODUCT]: T('mshop::ProductStatusTooltip::not_use_product'),
    [PRODUCT_STATUS.PB_ME]: T('mshop::ProductStatusTooltip::pb_me'),
  }[key]);

export const AFREECA_PRODUCT_STATUS_TOOLTIP = (key) =>
  ({
    [PRODUCT_STATUS.PROHIBITED]: '판매가 보류된 상품입니다.',
    [PRODUCT_STATUS.CHECKING]: '마플샵에서 심사 진행중인 상품입니다.',
    [PRODUCT_STATUS.PUBLIC]: '샵프리카에 노출되어 판매중인 상품입니다.',
    [PRODUCT_STATUS.PRIVATE]: '샵프리카에 비공개 처리되어 판매중지된 상품입니다.',
    [PRODUCT_STATUS.READY_CONFIRM]: T('mshop::ProductStatusTooltip::ready_confirm'),
    [PRODUCT_STATUS.NOT_CONFIRMED]: T('mshop::ProductStatusTooltip::not_confirmed'),
    [PRODUCT_STATUS.NOT_USE_PRODUCT]: T('mshop::ProductStatusTooltip::not_use_product'),
    [PRODUCT_STATUS.PB_ME]: T('mshop::ProductStatusTooltip::pb_me'),
  }[key]);
