import { defaults, go, mapObject, reject, tap } from 'fxjs/es';
import { rejectWrongCvTextImage, ungroupAllWithObjAttrs } from './util.js';
import { changeDpiDataUrl } from 'changedpi';
import { getTargetSizeByAttr } from './auto_phonecase_print_img.js';

export async function downloadAutoPhonecasePrintImg(product_color_id, projection_id) {
  function make_name(name) {
    return go(
      name,
      function (name) {
        if (name.indexOf('범퍼') !== -1 && name.indexOf('슬라이드') !== -1) return name.replace('범퍼', '');
        return name;
      },
      function (name) {
        if (name.indexOf('(무광)') !== -1) return name.replace('(무광)', '');
        return name;
      },
    );
  }
  const product_color = await $.get('/@api/product_color', {
    id: product_color_id,
  });
  /*DF*/
  /*온니 폰케이스*/
  const product_face = product_color.product_faces2.value[0];
  const background_color =
    product_color._.base_product_color.print_color_code || product_color._.base_product_color.color_code;
  const bpcf = product_color._.base_product_color._.base_product_color_faces[0];

  const base_product_name = make_name(product_color._.base_product.name);
  const bpf = bpcf._.base_product_face;
  const el_canvas = document.createElement('canvas');

  const ratio_for_ratio = 2;
  const pixel_per_mm = 11.8;
  const margin = 5 * pixel_per_mm * ratio_for_ratio;
  const base_product_name_height = 65 * ratio_for_ratio;
  const img_size = await getTargetSizeByAttr(product_face.cv_mask1);

  async function makeAttr(type) {
    if (type === '하드') {
      const ratio = ((bpf.outline_real_width_mm * pixel_per_mm) / img_size.width) * ratio_for_ratio;
      const background_size = mapObject((d) => d * ratio, await getTargetSizeByAttr(product_face.cv_mask2));
      return {
        ratio,
        background_attr: {
          fill: background_color,
          top: base_product_name_height,
          left: 0,
          width: background_size.width + margin * 2,
          height: background_size.height + margin * 2,
          globalCompositeOperation: 'destination-atop',
        },
        diff: {
          top: background_size.top - base_product_name_height - margin,
          left: background_size.left - margin,
        },
        canvas_attr: {
          width: background_size.width + margin * 2,
          height: background_size.height + margin * 2 + base_product_name_height * 2,
        },
      };
    } else if (type === '젤리') {
      const ratio = ((bpf.blue_line_real_width_mm * pixel_per_mm) / img_size.width) * ratio_for_ratio;
      const background_size = mapObject((d) => d * ratio, img_size);
      const background_real_size = {
        width: bpf.blue_line_real_width_mm * pixel_per_mm * ratio_for_ratio,
        height: bpf.blue_line_real_height_mm * pixel_per_mm * ratio_for_ratio,
      };
      const stroke_width = 2;
      return {
        ratio,
        background_attr: defaults(
          {
            fill: 'rgba(0,0,0,1)',
            // stroke: 'rgba(0, 0, 0, 1)',
            // strokeWidth: 2,
            top: base_product_name_height + margin,
            left: margin,
            rx: bpf.blue_line_real_radius_mm * pixel_per_mm * ratio_for_ratio,
            ry: bpf.blue_line_real_radius_mm * pixel_per_mm * ratio_for_ratio,
            globalCompositeOperation: 'destination-in',
          },
          background_real_size,
        ),
        stroke_attr: {
          fill: 'rgba(0,0,0,0)',
          stroke: 'rgba(0, 0, 0, 1)',
          strokeWidth: stroke_width,
          top: base_product_name_height + margin - stroke_width,
          left: margin - stroke_width,
          width: background_real_size.width + stroke_width,
          height: background_real_size.height + stroke_width,
          rx: bpf.blue_line_real_radius_mm * pixel_per_mm * ratio_for_ratio,
          // ry: bpf.blue_line_real_radius_mm * pixel_per_mm * ratio_for_ratio + 40
        },
        diff: {
          top: background_size.top - base_product_name_height - margin,
          left: background_size.left - margin,
        },
        canvas_attr: {
          fill: 'rgba(255,255,255,1)',
          width: background_real_size.width + margin * 2,
          height: background_real_size.height + margin * 2 + base_product_name_height * 2,
        },
      };
    }
  }

  const { ratio, canvas_attr, background_attr, diff, stroke_attr } = await makeAttr('하드');
  // const {
  //   ratio,
  //   canvas_attr,
  //   background_attr,
  //   diff,
  //   stroke_attr
  // } = await makeAttr('젤리');
  // const cv_mask2_location = mapObject(d => d * ratio, out_line_image_size);

  // const diff_top = cv_mask2_location.top;
  // const diff_left = cv_mask2_location.left;

  const fcanvas = new fabric.StaticCanvas(
    el_canvas,
    defaults(
      {
        enableRetinaScaling: false,
      },
      canvas_attr,
    ),
  );

  const background = new fabric.Rect(background_attr);
  const stroke = stroke_attr && new fabric.Rect(stroke_attr);

  const fontFamily = 'BareunBatang';
  await new FontFaceObserver(fontFamily).load(undefined, 30000);
  // const title
  const title = `#${projection_id} / ${base_product_name}`;
  const text = new fabric.Text(title, {
    textAlign: 'center',
    fontFamily,
    fontSize: 50 * ratio_for_ratio,
  });

  const designs = G.mp.maker.reject_ai(product_face.designs) || [];
  try {
    await go(
      designs,
      rejectWrongCvTextImage,
      reject((design) => design.visible === false),
      (designs2) => ungroupAllWithObjAttrs(fcanvas, designs2),
      tap(() => (G.mp.maker.is_auto_print = true)),
      _p.each(
        _p.pipe(
          (design) => G.mp.maker.from_cv_attrs_for_maker(design, ratio),
          function (item) {
            item.top = item.top - diff.top;
            item.left = item.left - diff.left;
            item && fcanvas.add(item);
          },
        ),
      ),
      tap(() => (G.mp.maker.is_auto_print = false)),
      _p.tap(function () {
        fcanvas.add(background);
        stroke && fcanvas.add(stroke);
      }),
      _p.tap(function () {
        fcanvas.add(text);
        text.center();
        text.top = 0;
      }),
      () => fcanvas.renderAll(),
      function () {
        const dataurl = el_canvas.toDataURL('image/png', 1);
        G.mp.maker.download(changeDpiDataUrl(dataurl, 300 * ratio_for_ratio), title);
        fcanvas.dispose();
      },
    );
  } catch (e) {
    G.mp.maker.is_auto_print = false;
  }
}
