import { MuiF } from '../../../../../../Mui/F/Function/module/MuiF.js';
import { VectorEditorStickerSingleCreatorPCMuiF } from '../Mui/module/VectorEditorStickerSingleCreatorPCMuiF.js';
import { go, tap } from 'fxjs/es';

export const makeSticker = ({
  title,
  price,
  art_board_size: { width: art_board_width, height: art_board_height },
  foreground_el,
  empty_template_el,
  is_need_cutting_line,
  can_set_background,
  background: { fill: background_fill = '#FFFFFF', opacity: background_opacity = 1 },
  preProcess,
  postProcess,
}) =>
  new Promise((resolve, reject) =>
    MuiF.openFrame(VectorEditorStickerSingleCreatorPCMuiF.frame, (frame, page, [tab]) => {
      const frame_closed = frame.closed;
      frame.closed = (frame_el, data) =>
        go(
          frame_closed.call(frame, frame_el, data),
          tap(() => resolve(data)),
        );

      const tab_appending = tab.appending;
      tab.appending = (tab_el) => {
        tab_el.__mp_title = title;
        tab_el.__mp_price = price;
        tab_el.__mp_is_need_cutting_line = !!is_need_cutting_line;
        tab_el.__mp_preProcess = preProcess;
        tab_el.__mp_postProcess = postProcess;
        tab_el.__mp_can_set_background = can_set_background;
        tab_el.__mp_background_color = background_fill;
        tab_el.__mp_background_opacity = background_opacity;
        tab_el.__mp_art_board_size = { width: art_board_width, height: art_board_height };
        tab_el.__mp_foreground_el = foreground_el;
        tab_el.__mp_empty_template_el = empty_template_el;
        return tab_appending.call(tab, tab_el);
      };
    }).catch(reject),
  );
