import { each, go, tap } from 'fxjs/es';
import { MuiF } from '../../../../../../Mui/F/Function/module/MuiF.js';
import { VectorEditorStickerSingleMobileMuiF } from '../Mui/module/VectorEditorStickerSingleMobileMuiF.js';
import { $findAll } from 'fxdom/es';

export const makeSticker = ({
  art_board_size: { width, height },
  foreground_el,
  empty_template_el,
  preProcess,
  postProcess,
  is_need_cutting_line,
  can_set_background,
  background: { fill: background_fill = '#ffffff', opacity: background_opacity = 1 } = {},
}) =>
  new Promise((resolve, reject) => {
    let tab_el;
    MuiF.openFrame(VectorEditorStickerSingleMobileMuiF.frame, (frame, page, [tab]) => {
      const frame_closed = frame.closed;
      frame.closed = (frame_el, data) =>
        go(
          frame_closed.call(frame, frame_el, data),
          tap(() => resolve(data)),
        );

      const tab_appending = tab.appending;
      tab.appending = (_tab_el) => {
        tab_el = _tab_el;

        _tab_el.__mp_art_board_size = { width, height };
        _tab_el.__mp_foreground_el = foreground_el;
        _tab_el.__mp_empty_template_el = empty_template_el;
        _tab_el.__mp_preProcess = preProcess;
        _tab_el.__mp_postProcess = postProcess;
        _tab_el.__mp_is_need_cutting_line = !!is_need_cutting_line;
        _tab_el.__mp_can_set_background = !!can_set_background;
        _tab_el.__mp_background_color = background_fill;
        _tab_el.__mp_background_opacity = background_opacity;

        return tab_appending.call(tab, _tab_el);
      };
    })
      .then(() => {
        go(
          tab_el,
          $findAll(
            `.icon_menu.footer.none[data-is_show="true"][data-is_active="false"][data-icon_type="add"]`,
          ),
          each((el) => el?.click?.()),
        );
      })
      .catch(reject);
  });
