import {
  isEmpty,
  compact,
  deepFlat,
  filter,
  find,
  flatMap,
  go,
  groupBy,
  head,
  html,
  last,
  map,
  partition,
  pluck,
  reject,
  sel,
  some,
  sortByDesc,
  strMap,
  sumBy,
  tap,
  uniq,
  join,
} from 'fxjs/es';
import * as L from 'fxjs/es/Lazy/index.js';
import { CateListConstantS } from '../../../../CateList/S/Constant/module/CateListConstantS.js';
import { CateListS } from '../../../../CateList/S/Function/module/CateListS.js';
import { GoodsTypeS } from '../../../../GoodsType/S/Function/module/GoodsTypeS.js';
import { getCvImage } from '../../../../Maker/F/CvImage/fs.js';
import { getCvText, hasNotBold } from '../../../../Maker/F/CvTextImage/fs.js';
import { changeCvObjectToImgSrc } from '../../../../Maker/F/draw_product_faces.js';
import { getFonts, rejectWrongCvTextImage } from '../../../../Maker/F/util.js';
import { isNeedPreview } from '../../../../Maker/S/categorize.js';
import { MShopUtilConstantS } from '../../../../MShop/Util/S/Constant/module/MShopUtilConstantS.js';
import { NewMakerCvObjectCvImageF } from '../../../../NewMaker/CvObject/CvImage/F/Function/module/NewMakerCvObjectCvImageF.js';
import { NewMakerProductStyleS } from '../../../../NewMaker/ProductStyle/S/Function/module/NewMakerProductStyleS.js';
import { UtilImageS } from '../../../../Util/Image/S/Function/module/UtilImageS.js';
import { UtilS } from '../../../../Util/S/Function/module/UtilS.js';
import { legacyHtml } from '../../../../Util/S/Function/util.js';
import { WowPressConstantS } from '../../../../WowPress/S/Constant/module/WowPressConstantS.js';
import { DfTaskImageWorkS } from '../../../Task/ImageWork/S/Function/module/DfTaskImageWorkS.js';
import { makeDfProjectionSidebarItemHtml } from './dfSidebarItemTmpl.js';
import { makePrjPaymentInfoHtml } from './paymentInfoTmpl.js';
import { makeDfProjectionShipItemsHtml } from './shipItemTmpl.js';
import { calculatePrintableFileShiboriCm, calculateShiboriForDesign } from './shibori.js';
import { OMPDosuConstantS } from '../../../../OMP/Dosu/S/Constant/module/OMPDosuConstantS.js';
import $dataStr from 'fxdom/es/dataStr.js';
import { BpOptionConstantS } from '../../../../BpOption/S/Constant/module/BpOptionConstantS.js';
import { isInhouseProjection, isShipToInhouse } from './inhouse.js';
import { isNessFromDfPrj } from './ness.js';
import { UtilArrayS } from '../../../../Util/Array/S/Function/module/UtilArrayS.js';
import { NewMakerPropertyBaseProductConstantS } from '../../../../NewMaker/Property/BaseProduct/S/Constant/module/NewMakerPropertyBaseProductConstantS.js';
import { UserProductS } from '../../../../UserProduct/S/Function/module/UserProductS.js';
import {
  LOC_OPT_ACTIVATE_ATTR_NAME,
  LOC_OPT_ENABLE_ATTR_NAME,
  LOC_OPT_REQUEST_CS,
  LOC_OPT_SEEN_PROOF,
} from '../../../../Maker/S/constant.js';

const makeUpCSHtml = (up_c_ss) =>
  go(
    up_c_ss,
    filter((up_c_s) => up_c_s.quantity),
    L.zipWithIndex,
    strMap(
      ([i, up_c_s]) => legacyHtml`
        <li class="row up_c_s_item ${i == 0 && 'selected'}" _id=${up_c_s.id} _sel="./(#${up_c_s.id})">
          <div class="double_check"></div>
          <div class="size">
            ${!up_c_s._.base_product_size.is_public || up_c_s._is_not_stock ? '품절 ' : ''}
            ${up_c_s._.base_product_size.short_name || up_c_s._.base_product_size.name}
          </div>
          <div class="quantity">${up_c_s.quantity}</div>
        </li>
      `,
    ),
  );

const makeUpCSOptionHtml = (up_c_ss) =>
  go(
    up_c_ss,
    filter((up_c_s) => up_c_s.quantity),
    L.zipWithIndex,
    strMap(
      ([i, up_c_s]) => html`
        <li class="row up_c_s_item ${i == 0 && 'selected'}" _id=${up_c_s.id} _sel="./(#${up_c_s.id})">
          <div class="double_check"></div>
          <div class="size">
            ${up_c_s._.spo_item.group_names_option_names?.map(({ option_name }) => option_name).join(' / ') ||
            '수량'}
          </div>
          <div class="quantity">${up_c_s.quantity}</div>
        </li>
      `,
    ),
  );

export const toPrintCm = (px, is_ai) => {
  if (px == null) return '-';
  const DPC_300 = 300 / 2.54;
  const DPC_72 = 72 / 2.54;
  return (px / (is_ai ? DPC_72 : DPC_300)).toFixed(1);
};

const makePrintableFileHtml =
  (dl, pf, up_c_s_list) =>
  ([i, file]) =>
    legacyHtml`
  <div
    class="file"
    _id="${file.id}"
    _url="${file.url}"
    _created_at="${file.created_at}"
    idx="${dl + i}"
    _sel="./(#${file.id})"
    _is_copied="${file._is_copied}"
    is_pd_land="${file.press_type_name?.includes('플떡')}"
  >
    <div
      class="select_press_type"
      el_class="press_types"
      _sel="press_types"
      current_value="${file.press_type_id || null}"
    >
      <div class="selected">${file.press_type_name || '선택 안됨'}</div>
    </div>
    <div class="img">
      <div class="id">#${file.id}</div>
      <img src="${G.to_150(file.url)}" alt="" />
    </div>
    <div class="remove_all">모두 삭제</div>
    <div class="remove">삭제</div>
    ${drawPrintableFileMeasure({ file, up_c_s_list, pf })}
    <a href="${file.ai_url || file.url}" data-is_ai="${!!file.ai_url}" download="${
      file.name
    }" target="_blank" class="download printable_file_download"
      >${file.ai_url ? `${last(file.ai_url.split('.'))} ` : ''}다운로드</a
    >
    <button
      type="button"
      data-href="${file.ai_url || file.url}"
      class="original_name_download"
      data-original_name="${file.original_name}"
      style="display: none"
    >
      다운로드
    </button>
  </div>
`;

const drawPrintableFileMeasure = ({ file, up_c_s_list, pf }) => {
  if (up_c_s_list == null || !up_c_s_list.length) {
    return '';
  } else {
    const bp_size_id = up_c_s_list[0].base_product_size_id;
    const is_ai = !!file.ai_url;

    if (Number(file.width) > 0 && Number(file.height) > 0) {
      const shibori_cm = calculatePrintableFileShiboriCm({ file, bp_size_id, pf });
      const has_png = !!file.png;
      const w = has_png ? file.png.width : file.width;
      const h = has_png ? file.png.height : file.height;

      return legacyHtml`
              <div class="justify-size">
                <div class="size-shibori">
                      <span>
                      ${shibori_cm ? `${shibori_cm.toFixed(1)} cm` : '-'}
                      </span>
                </div>
                <div class="size-justify-design-cm">
                      <span>
                        ${toPrintCm(w, is_ai)} x ${toPrintCm(h, is_ai)} cm
                      </span>
              </div>
            </div>
    `;
    } else {
      return '';
    }
  }
};

const canAutoPrint = (bp, pc) => {
  const name = bp.name.replace(/ /g, '');
  /* TODO 카테고리 하드코딩 */
  return !!(
    bp.cate_list_id == CateListConstantS.PHONE_ACC_LIST_ID &&
    name.indexOf('투명') == -1 &&
    (name.indexOf('하드케이스') != -1 ||
      name.indexOf('범퍼슬라이드') != -1 ||
      name.indexOf('범퍼케이스') != -1) &&
    bp._.base_product_faces[0].outline_real_width_mm &&
    bp._.base_product_faces[0].outline_real_height_mm &&
    new Date(pc.updated_at) > new Date('2019-12-03 14:15:00')
  );
};

const renderLocOptimizationActivateTextTmpl = (pf) => {
  const loc_opt = {
    enabled: pf?.[LOC_OPT_ENABLE_ATTR_NAME],
    activate: pf?.[LOC_OPT_ACTIVATE_ATTR_NAME] ?? false,
    seen_proof: pf?.[LOC_OPT_SEEN_PROOF] ?? false,
    cs_requested: pf?.[LOC_OPT_REQUEST_CS] ?? false,
  };

  // 레거시 대응
  if (loc_opt.enabled == null) {
    return loc_opt.activate
      ? html`
          <div class="pf_loc_optimization">
            <span>위치 조정 요청</span>
          </div>
        `
      : '';
  }

  if (!loc_opt.enabled) return '';

  let log_opt_text = '조정&nbsp;';

  if (loc_opt.activate) {
    log_opt_text += `<span style="color:forestgreen;font-weight: bold;">요청함&nbsp;!</span>`;
  } else {
    if (loc_opt.seen_proof) {
      log_opt_text += `<span style="color:blue;font-weight: bold;">확인O&nbsp;요청X</span>`;
    } else {
      log_opt_text += `<span style="color:dimgrey;font-weight: bold;">확인X</span>`;
    }
  }

  return html`
    <div class="pf_loc_opt ${loc_opt.activate ? 'activate' : ''}">
      <span>${log_opt_text}</span>
    </div>
    ${loc_opt.activate
      ? ''
      : `<label>CS 요청<input class="pf_loc_opt_cs" type="checkbox" ${
          loc_opt.cs_requested ? 'checked' : ''
        }/></label>`}
  `;
};

const makeProductFaceListHtml = (pc, is_printable, bp, up_c_s_list, is_wow_order) => {
  return legacyHtml`
  <div class="product_face_list grid">
    ${
      sel('product_faces2.value.0.face_name', pc) == '뒷면' ||
      sel('product_faces2.value.0.face_name', pc) == '아동 뒷면' ||
      sel('product_faces2.value.0.face_name', pc) == '뒤'
        ? legacyHtml`
          <div class="no_front">
            <div class="name">앞면 없음</div>
          </div>
        `
        : ''
    }
    ${go(
      sel('product_faces2.value', pc),
      L.zipWithIndex,
      strMap(
        ([i, pf]) =>
          legacyHtml`
          <div
            class="product_face row"
            _sel="./product_faces2->value->${i}"
            idx="${i}"
            has_more_design="${go(
              pf.designs,
              G.mp.maker.reject_ai,
              reject((cv_obj) => cv_obj._data.clone_parent_cid),
              filter((d) => d.visible),
              (ds) => ds.length > 3,
            )}"
            style="${is_wow_order ? 'width : 120px' : ''}"
          >
            <div class="left">
              <div class="name">${UtilS.escape(pf.face_name)}</div>
              <div class="thumb">
                <div class="img canvas_120">
                  <canvas></canvas>
                  <div class="don_loader_wrap2">
                    <div class="don_loader_img2"></div>
                  </div>
                </div>
              </div>
              ${is_printable ? '' : renderLocOptimizationActivateTextTmpl(pf)}
              ${
                !is_printable && pf?.design_size
                  ? legacyHtml`<div class="justify-size-info">
                      ${renderShiboriHtml({ pf, base_product_size_id: up_c_s_list[0]?.base_product_size_id })}
                      <div>
                        <span class="total-design-size">${pf.design_size?.width_cm ?? '-'} x ${
                      pf.design_size?.height_cm ?? '-'
                    } cm</span>
                      </div>
                    </div>`
                  : ``
              }
              ${
                !is_printable && NewMakerProductStyleS.possibleAutoPrint(bp)
                  ? legacyHtml`
                <div class="download_ci_case designs" _sel="./designs">
                  <button type="button" class="auto_print_img by_customer" style="margin-left:0; font-size:11px">
                      인쇄용 파일(고객)
                    </button>
                </div>
                `
                  : !is_printable && canAutoPrint(bp, pc)
                  ? legacyHtml`
                      <div class="download_ci_case designs" _sel="./designs">
                        <button type="button" class="dci_case">인쇄용 파일</button>
                      </div>
                    `
                  : ``
              }
              ${
                is_printable &&
                legacyHtml`
                <div class="download_ci designs" _sel="./designs">
                  <button type="button" class="dci">합성 다운로드</button>
                </div>
                <div class="printable_files" _sel="./printable_files">
                  <div class="options">
                    <label>
                      <input type="checkbox" face_name="${pf.face_name}" />
                      업로드 선택
                    </label>
                  </div>
                  <div class="list">
                    ${go(
                      pf.printable_files,
                      L.zipWithIndex,
                      strMap(makePrintableFileHtml(sel('designs.length', pf), pf, up_c_s_list)),
                    )}
                  </div>
                </div>
                ${
                  canAutoPrint(bp, pc)
                    ? legacyHtml`
                      <div class="download_ci_case designs" _sel="./designs">
                        <button type="button" class="dci_case">폰케이스 인쇄용</button>
                      </div>
                    `
                    : NewMakerProductStyleS.isPDLandBp(bp)
                    ? legacyHtml`
                      <div class="download_ci_case designs" _sel="./designs">
                        <button type="button" class="template_print">템플릿 인쇄용</button>
                      </div>
                    `
                    : NewMakerProductStyleS.possibleAutoPrint(bp)
                    ? html`
                        <div class="download_ci_case designs" _sel="./designs">
                          <button type="button" class="auto_print_img template_meta_print_file">
                            인쇄용 파일
                          </button>
                        </div>
                      `
                    : ``
                }
              `
              }
            </div>
            
            <div class="right">
              <div class="designs" _sel="./designs">
                ${
                  pf.designs?.length == 0
                    ? '<div style="margin-top: 64px;">무지 주문</div>'
                    : go(
                        pf.designs,
                        G.mp.maker.reject_ai,
                        rejectWrongCvTextImage,
                        reject((cv_obj) => cv_obj._data.clone_parent_cid),
                        filter((d) => d.visible),
                        map(function (d) {
                          const design_src_id = _p.uniqueId('design_src_id');
                          const need_to_data_url =
                            [
                              'cv_text',
                              'cv_group',
                              'cv_pattern',
                              'cv_text_image',
                              'cv_text_image_pattern',
                            ].includes(d._data.cv_type) || !!d._data[OMPDosuConstantS._DATA_NAMES.DOSU_COLOR];
                          if (need_to_data_url)
                            go(
                              void 0,
                              () => G.mp.maker.from_cv_attrs_for_maker(d, 100 / (d.width * d.scaleX), true),
                              (d) =>
                                d._data.cv_type === 'cv_group'
                                  ? changeCvObjectToImgSrc(d, 40)
                                  : _p.v(d, '_element.src'),
                              function (src) {
                                setTimeout(function () {
                                  $.attr($1('#' + design_src_id), { src });
                                }, 500);
                              },
                            );
                          return need_to_data_url
                            ? { design: d, src: '', id: design_src_id }
                            : {
                                design: d,
                                src:
                                  d._data.cv_type == 'cv_old_text' ? G.to_original(d.src) : G.to_150(d.src),
                                id: design_src_id,
                              };
                        }),
                        tap(function () {
                          return getFonts();
                        }),
                        L.zipWithIndex,
                        strMap(
                          ([i, info]) => legacyHtml`
                          <div
                            class="design"
                            _sel="./${i}"
                            idx="${i}"
                            cv_type="${info.design._data.cv_type}"
                            is_marpple_image="${!!info.design._data.is_marpple_image}"
                            original_is_ai="${!!info.design._data.original_is_ai}"
                            is_embro="${!!info.design._data.is_embro}"
                            is_low_quality_agree="${!!info.design._data.is_low_quality_agree}"
                            cid="${info.design.cid}"
                          >
                            <div class="row">
                              <div class="img">
                                <img src="${UtilS.escape(info.src) || ''}" id="${info.id}" />
                              </div>
                              <div class="type">
                                <div class="name"></div>
                                <div class="color">${info.design._data.press_color_name || '색없음'}</div>
                              </div>
                              <div class="down">
                                <button type="button" class="edit">편집</button>
                                <button type="button" class="down">다운</button>
                                <button type="button" class="original_down">
                                  ${info.design._data.original_is_ai ? 'Ai' : '원본'}
                                </button>
                                ${
                                  info.design._data.cv_type === 'cv_text_image'
                                    ? html`<button
                                        type="button"
                                        class="copy_cv_text_image_data_text_info_text"
                                      >
                                        복사
                                      </button>`
                                    : ``
                                }
                              </div>
                            </div>
                            <div class="size_info">
                              <div class="marpple_image_name">
                                ${
                                  info.design._data.name ||
                                  go(getCvText(info.design), (cv_text) => {
                                    if (!cv_text) return;
                                    return (
                                      sel('fontFamily', cv_text) || sel('styles.0.0.fontFamily', cv_text)
                                    );
                                  }) ||
                                  '이름없음'
                                }
                              </div>
                              <div
                                class="has_not_bold"
                                has_not_bold="${go(getCvText(info.design), (cv_text) => {
                                  if (!cv_text) return false;
                                  return sel('fontWeight', cv_text) === 'bold' && hasNotBold(cv_text);
                                })}"
                              >
                                볼드 지원 안함
                              </div>
                              <div class="px">
                                ${go(getCvImage(info.design), (cv_image) => {
                                  if (!cv_image)
                                    return (
                                      (info.design._data.image_width || '') +
                                      ' x ' +
                                      (info.design._data.image_height || '') +
                                      ' px'
                                    );
                                  if (!cv_image._data.image_width && !cv_image._data.image_height) return '';
                                  return (
                                    (cv_image._data.image_width || '') +
                                    ' x ' +
                                    (cv_image._data.image_height || '') +
                                    ' px'
                                  );
                                })}
                              </div>
                              <div class="position"></div>
                              <div class="cm">
                                <span>크기</span> ${
                                  info.design._data.cv_text_image
                                    ? info.design._data.cv_text_image._data.width_cm
                                    : info.design._data.width_cm
                                }
                                x
                                ${
                                  info.design._data.cv_text_image
                                    ? info.design._data.cv_text_image._data.height_cm
                                    : info.design._data.height_cm
                                }
                                cm
                              </div>
                              ${
                                info.design._data.fitted_size
                                  ? html`
                                      <div class="price_mm">
                                        <span>가격</span> ${info.design._data.fitted_size.width} x
                                        ${info.design._data.fitted_size.height} mm
                                      </div>
                                    `
                                  : ''
                              }
                              ${
                                !NewMakerCvObjectCvImageF.hasRequestBgRemoval(info.design)
                                  ? ''
                                  : '<div class="request_bg_removal">배경 지움</div>'
                              }
                              ${!info.design.flipX ? '' : `<div class="flip">좌우반전</div>`}
                              ${!info.design.flipY ? '' : `<div class="flip">상하반전</div>`}
                            </div>
                          </div>
                        `,
                        ),
                      )
                }
              </div>
              <div class="show_all_design"></div>
            </div>
          </div>
        `,
      ),
    )}
  </div>
`;
};

const renderShiboriHtml = ({ pf, base_product_size_id }) => {
  if (base_product_size_id == null) {
    return '';
  }
  const shibori_str = calculateShiboriForDesign({ pf, base_product_size_id });
  return shibori_str
    ? legacyHtml`
        <div>
          <span class="total-design-shibori">${shibori_str.toFixed(1)} cm</span>
        </div>
    `
    : '';
};

const pcName = (up_c) => {
  const { base_product, product_color } = up_c._;
  if (up_c.store_id) {
    /* 키링일때 */
    /* TODO 카테고리 하드코딩 */
    if (base_product.cate_item_id == CateListConstantS.KEYRING_ITEM_ID) {
      return product_color.name ? `${product_color.name} - ${base_product.name}` : base_product.name;
    }
  }
  return product_color.name ? `${base_product.name} - ${product_color.name}` : base_product.name;
};

const optionName = (selected_option_group) => {
  return selected_option_group?.option_names
    ? ` [옵션: ${UserProductS.naming.makeOptionNames1({ selected_option_group })}]`
    : '';
};

const makeBpName = (up_c) => {
  const color_name = isNeedPreview(up_c._.base_product)
    ? ''
    : up_c._.base_product_color.name2 || up_c._.base_product_color.name;
  const outsource_company = up_c._.base_product._.outsouring_company?.name;
  const badge = go(
    up_c._.base_product._.custom_badges,
    map((badge) => badge.name),
    join(','),
  );

  const is_auto_task_set = !!up_c._.base_product.task_set_id || !!up_c._.base_product.is_not_image_task;

  return html`
    <span class="name">
      ${color_name ? html`&lt;${color_name}&gt;` : ''} ${pcName(up_c)}
      ${optionName(up_c._.product_color?._?.selected_option_group)} ${badge ? `[${badge}]` : ``}
      ${!up_c._.base_product.is_use &&
      !up_c._.base_product.is_use_en &&
      !up_c._.base_product.is_use_jp &&
      !up_c._.base_product.is_use_line &&
      !up_c._.base_product.is_use_line_en &&
      !up_c._.base_product.is_use_creator &&
      !up_c._.base_product.is_use_creator_en &&
      !up_c._.base_product.is_use_creator_jp
        ? ' (대체 혹은 판매 종료)'
        : ''}
      ${outsource_company ? ` <${outsource_company}>` : ''}
      ${is_auto_task_set
        ? '<span class="task_stat_label auto_task">자동 태스크</span>'
        : '<span class="task_stat_label manual_task">수동 할당 태스크</span>'}
    </span>
  `;
};

export const makeDfProjectionListUpCItemsHtml = (up) => {
  const {
    _: { up_cs },
  } = up;
  // 입고 상품
  if (GoodsTypeS.isTpl(up.goods_type_id)) {
    return html`
      <div class="up_c_list" _sel="./_->up_cs">
        ${go(
          up_cs,
          filter((up_c) => pluck(['quantity'], up_c._.up_c_ss).reduce((a, b) => a + b)),
          (up_cs2) => (up_cs2.length ? up_cs2 : [head(up_cs)]),
          strMap((up_c) => {
            const thumbnail_url = go(
              sel('_.product_color.thumbnails.value', up_c),
              find(sel('is_thumb')),
              sel('url'),
            );
            const item_orders = up_c._?.item_orders;
            let latest_item_order;
            if (UtilArrayS.isArrayOk(item_orders)) {
              latest_item_order = item_orders[item_orders.length - 1];
            }
            return html`
              <div class="cell up_c_item" _sel="./(#${up_c.id})">
                <div class="title${up._.base_product.df_name_color || ''}">
                  <button type="button" class="print_sku_labels"></button>
                  <!-- <buton type="button" class="print_bp_labels" style="margin-right:8px;">품표</button> -->
                  <span draggable="true">
                    <span>|||</span>
                    <span class="name">${UtilS.escape(up_c._.product_color.name)}</span>
                  </span>
                  ${latest_item_order
                    ? html`<span
                        class="current_item_order_status"
                        _sel="./_->item_orders->(#${latest_item_order.id})->_->order_process_step"
                        >${latest_item_order._.order_process_step.name}</span
                      >`
                    : ``}

                  <span class="name">${UtilS.escape(up_c._.product_color.name)}</span>
                  <button type="button" class="edit_sizes">수량 변경</button>
                </div>
                ${go(sel('_.spo_text_option_values', up), (spo_text_option_values) => {
                  if (!spo_text_option_values || !spo_text_option_values.length) return '';

                  return html`
                    <div class="spo_text_option_values">
                      <div class="spo_text_option_values_title">옵션 직접 입력형</div>
                      ${strMap((sto_val) => {
                        return `
                            <div class="spo_text_option_value">
                              <span>${sel('_.spo_text_option.name', sto_val)} -&nbsp;</span>
                              <span>${sto_val.value}</span>
                            </div>
                          `;
                      }, spo_text_option_values)}
                    </div>
                  `;
                })}
                <div class="grid up_c_ss">
                  <div class="col">
                    <ul class="up_c_s_list" _sel="./_->up_c_ss">
                      ${makeUpCSOptionHtml(up_c._.up_c_ss)}
                      ${filter((up_c_s) => sel('quantity', up_c_s), up_c._.up_c_ss).length == 1
                        ? ''
                        : `
                            <li class="up_c_s_item row total">
                              <div class="double_check total"></div>
                              <div class="size">합계</div>
                              <div class="quantity">${sumBy((u) => u.quantity, up_c._.up_c_ss)}</div>
                            </li>`}
                    </ul>
                  </div>
                  <div class="col prd_cs">
                    <div class="product_color" _sel="./_->product_color">
                      <div class="product_face">
                        <img
                          src="${UtilS.escape(
                            UtilImageS.getResizedUrl({ url: thumbnail_url, width: 200, quality: 80 }),
                          )}"
                          class="canvas_200"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            `;
          }),
        )}
      </div>
    `;
  }

  const is_pure_biz = go(undefined, () => {
    try {
      const bp_discount_range = go(up._.base_product._?.bp_discount_set?._?.bp_discount_ranges, head);
      if (!bp_discount_range) return;
      return bp_discount_range.start !== 1;
    } catch (e) {}
  });

  const is_dosu_1_or_2 =
    !!up._.base_product?.maker_features?.[NewMakerPropertyBaseProductConstantS.DOSU_1_OR_2];
  return up.base_product_id == null
    ? html`<div class="up_c_list" style="padding: 8px;">삭제된 상품</div>`
    : html`
        <div class="up_c_list" _sel="./_->up_cs">
          ${go(
            up_cs,
            filter((up_c) => pluck(['quantity'], up_c._.up_c_ss).reduce((a, b) => a + b)),
            (up_cs2) => (up_cs2.length ? up_cs2 : [head(up_cs)]),
            strMap((up_c) => {
              const up_c_ss = up_c._.up_c_ss;
              const has_bp_label = up_c._.base_product._?.label?.id;
              const biz_option_values = up_c._.product_color.product_faces2?.biz;

              const item_orders = up_c._?.item_orders;
              let latest_item_order;
              if (UtilArrayS.isArrayOk(item_orders)) {
                latest_item_order = item_orders[item_orders.length - 1];
              }
              const wow_order = up._.wow_orders;
              return html`
                <div class="cell up_c_item" _sel="./(#${up_c.id})">
                  <div
                    class="title
                    ${up._.base_product.df_name_color || ''}"
                  >
                    <button type="button" class="print_sku_labels"></button>
                    ${has_bp_label
                      ? html`<button
                          type="button"
                          class="print_bp_labels"
                          style="margin-right:8px;background-color:black;color:white;"
                        >
                          품표
                        </button>`
                      : ``}
                    ${latest_item_order
                      ? html`<span
                          class="current_item_order_status"
                          _sel="./_->item_orders->(#${latest_item_order.id})->_->order_process_step"
                          >${latest_item_order._.order_process_step.name}</span
                        >`
                      : ``}

                    <button type="button" class="copy_file_name" style="margin-right:8px;">복사</button>
                    ${is_pure_biz === true
                      ? html`<button
                          type="button"
                          class="biz_requests"
                          style="margin-right:8px;"
                          data-fx-json="${$dataStr(biz_option_values ?? {})}"
                        >
                          ${makeBizReqButtonText({ biz_option_values, is_dosu_1_or_2 })}
                        </button>`
                      : ''}
                    <span draggable="true">
                      <span>|||</span>
                      ${makeBpName(up_c)}
                    </span>
                    ${makeBpName(up_c)}
                    <span
                      class="df-image-work-tag"
                      data-work_type="${DfTaskImageWorkS.getWorkType(up._.base_product)}"
                      >${DfTaskImageWorkS.getWorkType(up._.base_product)}</span
                    >
                    <button type="button" class="edit_sizes">수량 변경</button>
                    <div style="margin-left: auto; display: flex; align-items: center">
                      ${!wow_order || isEmpty(wow_order)
                        ? ``
                        : html`
                            <div class="wow_ordnum">
                              ${wow_order?.ordnum
                                ? html`<span>${wow_order?.ordnum}</span>`
                                : wow_order.type === WowPressConstantS.WOW_STATUS.CREATED ||
                                  wow_order.type === WowPressConstantS.WOW_STATUS.HOLDING
                                ? html`<label
                                    class="holding_order_wrap ${wow_order.type ===
                                    WowPressConstantS.WOW_STATUS.HOLDING
                                      ? 'checked'
                                      : ''}"
                                    ><input
                                      class="holding_wow_order"
                                      type="checkbox"
                                      ${wow_order.type === WowPressConstantS.WOW_STATUS.HOLDING
                                        ? 'checked'
                                        : ''}
                                    />주문 보류</label
                                  >`
                                : ''}
                              <a
                                target="_blank"
                                href="${up._.pdf_file?.pdf_url}"
                                style="margin: 5px; text-decoration: underline"
                                >파일</a
                              >
                            </div>
                          `}
                      <div class="up-c-item-id">상품 상세 번호 #${up_c.id}</div>
                    </div>
                  </div>
                  <div class="grid up_c_ss">
                    <div class="col">
                      <ul class="up_c_s_list" _sel="./_->up_c_ss">
                        ${makeUpCSHtml(up_c._.up_c_ss)}
                        ${filter((up_c_s) => sel('quantity', up_c_s), up_c._.up_c_ss).length == 1
                          ? ''
                          : `
                            <li class="up_c_s_item row total">
                              <div class="double_check total"></div>
                              <div class="size">합계</div>
                              <div class="quantity">${sumBy((u) => u.quantity, up_c._.up_c_ss)}</div>
                            </li>`}
                      </ul>
                    </div>
                    <div class="col prd_cs">
                      <div class="product_color" _sel="./_->product_color">
                        ${makeProductFaceListHtml(
                          up_c._.product_color,
                          undefined,
                          up_c._.base_product,
                          up_c_ss,
                          !isEmpty(wow_order),
                        )}
                      </div>
                      ${sel('_.printable_product.id', up_c)
                        ? html`
                            <div class="printable product_color" _sel="./_->printable_product">
                              <h4>인쇄용 시안</h4>
                              ${makeProductFaceListHtml(
                                up_c._.printable_product,
                                true,
                                up_c._.base_product,
                                up_c_ss,
                              )}
                            </div>
                          `
                        : ''}
                    </div>
                  </div>
                </div>
              `;
            }),
          )}
        </div>
      `;
};

const makeBizReqButtonText = ({ biz_option_values, is_dosu_1_or_2 }) => {
  const biz_file_ref_url = biz_option_values?.[BpOptionConstantS.BIZ_PF2_DATA_KEYS.FILE_REF_URL];
  const biz_memo = biz_option_values?.[BpOptionConstantS.BIZ_PF2_DATA_KEYS.REQ_MEMO];

  const has_ref_file = !!biz_file_ref_url && biz_file_ref_url !== '';
  const has_biz_memo = !!biz_memo && biz_memo !== '';

  const STYLE = 'font-weight:bold;color:#43ff3c;display:inline;margin-left:4px';
  const dosu_text = `${is_dosu_1_or_2 ? '[1~2도]' : ''}`;
  if (has_ref_file && has_biz_memo) {
    return `비즈상품 ${dosu_text} - <p style="${STYLE}">고객요청(1) + 파일(1)</p>`;
  }
  if (has_ref_file) {
    return `비즈상품 ${dosu_text} - <p style="${STYLE}">고객 파일(1)</p>`;
  }
  if (has_biz_memo) {
    return `비즈상품 ${dosu_text} - <p style="${STYLE}">고객 요청(1)</p>`;
  }

  return `비즈상품 ${dosu_text}`;
};

export const makeUpTitle = ({ goods_type_id }) => {
  return go(
    goods_type_id,
    () => {
      if (GoodsTypeS.isMps(goods_type_id)) {
        return '<span style="color:#002aff;">마플샵 상품</span>';
      } else if (GoodsTypeS.isTpl(goods_type_id)) {
        return '<span style="color:#02bc77;">마플샵 입고 상품</span>';
      } else {
        return '<span>주문제작 상품</span>';
      }
    },
    (up_title) => {
      return html`<span style="font-weight: bold;font-size:16px;">${up_title}</span>`;
    },
  );
};

export const mergeTplUpsForPrj = (prj) => {
  return (ups) => {
    if (prj.collabo_type == 'creator' && prj.merged_type !== 'parent') {
      const [tpl_ups, mps_ups] = partition(({ goods_type_id }) => {
        return GoodsTypeS.isTpl(goods_type_id);
      }, ups);
      const new_tpl_ups = go(
        tpl_ups,
        groupBy((up) => {
          return up._.product_color.product_id;
        }),
        Object.entries,
        map(([, ups]) => {
          const up = ups[0];
          const up_c = up._.up_cs[0];
          return {
            ...up,
            _: {
              ...up._,
              up_cs: [
                {
                  ...up_c,
                  _: {
                    ...up_c._,
                    up_c_ss: go(
                      ups,
                      flatMap((up) => up._.up_cs),
                      flatMap((up_c) => up_c._.up_c_ss),
                    ),
                  },
                },
              ],
            },
          };
        }),
      );
      return new_tpl_ups.concat(mps_ups);
    }
    return ups;
  };
};

const makeUpItemsHtml = (prj) => {
  return go(
    prj._.ups,
    filter((up) =>
      deepFlat(map((up_c) => pluck(['quantity'], up_c._.up_c_ss), up._.up_cs)).reduce((a, b) => a + b),
    ),
    mergeTplUpsForPrj(prj),
    sortByDesc(({ goods_type_id }) => {
      return GoodsTypeS.isTpl(goods_type_id);
    }),
    sortByDesc(({ partial_delivery_at }) => !partial_delivery_at),
    strMap((up) => {
      const has_partial_delivery_policy = box.sel('is_user->_->policies->partial_delivery_policy');
      try {
        up.memo = typeof up.memo == 'string' ? JSON.parse(up.memo) : up.memo;
      } catch (e) {
        up.memo = { worker: up.memo.replace('{"worker": "', '').replace('"}', '') };
      }
      return html`
        <div
          class="grid up_item"
          _id="${up.id}"
          _sel="./(#${up.id})"
          style="${up.partial_delivery_at ? 'border : 5px solid lightseagreen' : ''}"
        >
          <div class="cell head_between">
            <div class="title">${makeUpTitle(up)}</div>
            <div class="right">
              <div class="pdf_printing_file_issue">
                ${up._.pdf_file.issue_manufacturer
                  ? up._.pdf_file.issue_customer
                    ? html`<div class="pdf-issue-report">이슈 수정</div>`
                    : html`<div class="pdf-issue-report">이슈 작성</div>`
                  : html``}
                ${up._.pdf_file.issue_customer
                  ? html`<div class="pdf-issue-reorder-waiting">재 업로드 대기</div>`
                  : html``}
              </div>
              <div class="product_no">
                <div class="partial-delivery-wrap ${up.partial_delivery_at ? 'checked' : ''}">
                  <label>
                    ${has_partial_delivery_policy
                      ? html`<input
                          type="checkbox"
                          class="partial-delivery"
                          ${up.partial_delivery_at ? 'checked' : ''}
                        />`
                      : ''}
                    ${up.partial_delivery_at || has_partial_delivery_policy ? '부분 출고' : ''}
                  </label>
                </div>
                <button type="button" class="download_sop">.</button>
                <div class="id">상품 번호 #${up.id}</div>
              </div>
            </div>
          </div>
          ${sel('memo.worker', up)
            ? html`
                <div class="up_memo up_memo_worker">
                  <div class="body">${UtilS.linkify(sel('memo.worker', up))}</div>
                </div>
              `
            : ''}
          ${sel('memo.seller', up)
            ? html`
                <div class="up_memo up_memo_seller">
                  <div class="body"><b>셀러상품요청:</b> ${UtilS.linkify(sel('memo.seller', up))}</div>
                </div>
              `
            : ''}
          ${sel('memo.seller_all', up)
            ? html`
                <div class="up_memo up_memo_seller_all">
                  <div class="body"><b>셀러공통요청:</b> ${UtilS.linkify(sel('memo.seller_all', up))}</div>
                </div>
              `
            : ''}
          ${makeDfProjectionListUpCItemsHtml(up)}
        </div>
      `;
    }),
  );
};

export const makeDfProjectionListBpStocksHtml = (bp_stocks) => legacyHtml`
  <table>
    ${strMap(
      (bp_stock) => legacyHtml`
        <tr class="bp_stock" _id="${bp_stock.id}" _sel="./(#${bp_stock.id})">
          <td class="bp_name">${bp_stock._.base_product.name}</td>
          <td class="color">
            <span class="code" style="background: ${bp_stock._.base_product_color.color_code}"></span>
            <span class="name"
              >${bp_stock._.base_product_color.name2 || bp_stock._.base_product_color.name}</span
            >
          </td>
          <td class="size_name">${bp_stock._.base_product_size.name}</td>
          <td class="quantity">${bp_stock._.quantity}</td>
        </tr>
      `,
      bp_stocks,
    )}
  </table>
`;

export function langToKR(lang) {
  if (lang == 'en') return '영문';
  if (lang == 'jp') return '일문';
  return '국문';
}

const SHOP_DOMAINS = {
  SHOPIFY: 'shopify',
  SHOP: 'shop',
  NBOX: 'nbox',
  NESS: 'ness',
  AFREECA: 'sooptore',
};

const SHOP_PRJ_SIDEBAR_PROPERTIES = {
  bg_color: {
    [SHOP_DOMAINS.SHOPIFY]: '#5e8e3e',
    [SHOP_DOMAINS.SHOP]: '#002aff',
    [SHOP_DOMAINS.NBOX]: '#b4e114',
    [SHOP_DOMAINS.NESS]: '#53b7f3',
    [SHOP_DOMAINS.AFREECA]: '#0545b1',
  },
  color: {
    [SHOP_DOMAINS.SHOPIFY]: '#111',
    [SHOP_DOMAINS.SHOP]: '#fff',
    [SHOP_DOMAINS.NBOX]: '#111',
    [SHOP_DOMAINS.NESS]: '#111',
    [SHOP_DOMAINS.AFREECA]: '#fff',
  },
  name: {
    [SHOP_DOMAINS.SHOPIFY]: '쇼피파이',
    [SHOP_DOMAINS.SHOP]: '마플샵',
    [SHOP_DOMAINS.NBOX]: 'NBOX',
    [SHOP_DOMAINS.NESS]: 'NESS',
    [SHOP_DOMAINS.AFREECA]: '숲토어',
  },
};

const getShopDomainTagFromDfPrj = (prj) => {
  if (isInhouseProjection({ prj })) return SHOP_DOMAINS.NBOX;
  if (isNessFromDfPrj({ prj })) return SHOP_DOMAINS.NESS;
  if (prj.is_shopify) return SHOP_DOMAINS.SHOPIFY;
  if (prj._?.store?._?.crew?.crew_id == MShopUtilConstantS.SOOP_MARPPLESHOP_CREW_ID)
    return SHOP_DOMAINS.AFREECA;
  return SHOP_DOMAINS.SHOP;
};

const creatorPrjSidebarTitleTd = (prj) => {
  const shop_domain = getShopDomainTagFromDfPrj(prj);

  const TITLE_STYLE = `background:${SHOP_PRJ_SIDEBAR_PROPERTIES.bg_color[shop_domain]};color:${SHOP_PRJ_SIDEBAR_PROPERTIES.color[shop_domain]};`;
  const shipping_tag =
    shop_domain !== SHOP_DOMAINS.NBOX ? '' : isShipToInhouse({ prj }) ? ' ➔ 사내' : ' ➔ 개인';

  switch (prj.merged_type) {
    case 'parent': {
      return html`
        <td class="shop_title" colspan="4" style="${TITLE_STYLE}box-shadow: 0 0 1px 3px #333">
          <span style="font-size:16px;line-height:20px">
            <b>${SHOP_PRJ_SIDEBAR_PROPERTIES.name[shop_domain]}</b>
          </span>
          <span style="line-height:22px;">&nbsp병합 (제작용)</span>
        </td>
      `;
    }
    case 'sample': {
      return html`
        <td class="shop_title" colspan="4" style="${TITLE_STYLE}">
          <span style="font-size:16px;line-height:20px">
            <b>${SHOP_PRJ_SIDEBAR_PROPERTIES.name[shop_domain]}</b>
          </span>
          <span style="line-height:22px;">&nbsp샘플 (제작용) ${shipping_tag}</span>
        </td>
      `;
    }
    default: {
      const is_promo = ['SUPPORT_SELLER_M_SGN', 'NO_PRINT_NO_PROFIT_M_SGN'].includes(prj.promotion_code);
      return html` <td class="shop_title" colspan="4" style="${TITLE_STYLE}">
        <span style="font-size:16px;line-height:20px">
          <b>${SHOP_PRJ_SIDEBAR_PROPERTIES.name[shop_domain]}</b>
        </span>
        <span style="line-height:22px;">&nbsp${is_promo ? '(서포트)' : '(배송용)'}${shipping_tag}</span>
      </td>`;
    }
  }
};

export const makeProjectionItemHeadHtml = UtilS.catchHi(function (prj) {
  if (prj.collabo_type === 'line') {
    return html`<tr>
      <td colspan="4" style="text-align: center; background: rgb(0, 214, 107);">
        <b>라인프렌즈 크리에이터</b>
      </td>
    </tr>`;
  }
  if (prj.collabo_type === 'creator') {
    const isNESS = isNessFromDfPrj({ prj });

    return html` ${creatorPrjSidebarTitleTd(prj)}
      ${prj.merged_type === 'parent'
        ? html`<tr>
            <td colspan="4" style="text-align: center;">
              <form
                method="POST"
                action="/@api/waybill/down/merged_parent"
                class="waybill_form_in_projection"
              >
                <input type="hidden" name="projection_id" value="${prj.id}" />
                <input type="hidden" name="merged_type" value="merged" />
                <input type="hidden" name="collabo_type" value="creator" />
                <select name="shipping_company_id" style="margin: 5px 0;">
                  <option value="">택배사를 선택해 주세요.</option>
                  <option value="no" disabled="">----------------</option>
                  <option value="cj">대한통운</option>
                  <option value="doora">두라</option>
                  <option value="dhl">DHL</option>
                  <option value="ems">EMS</option>
                </select>
                <button type="submit" style="margin: 5px 0;">병합주문 운송장 폼 다운</button>
              </form>
              <button class="print_children_waybills">배송용 운송장 전체 출력</button>
              <button class="save_lgl_express_for_merged_parent" style="margin-top:5px;">
                해외 LGL 특송 일괄 다운
              </button>
              ${isNESS
                ? html`<button class="print_hanjin_inbound_orders_for_pod" style="margin-top:5px;">
                    한진 POD 입고주문 출력
                  </button>`
                : ''}
            </td>
          </tr>`
        : ''}
      ${prj.merged_type === 'sample'
        ? html`
            <tr>
              <td colspan="4" style="text-align: center;">
                ${isNESS
                  ? html`<button class="print_hanjin_inbound_orders_for_pod" style="margin-top:5px;">
                      한진 POD 입고주문 출력
                    </button>`
                  : ''}
              </td>
            </tr>
          `
        : ``}
      <tr class="store_sales_period">
        <th>판매시작</th>
        <td colspan="3">
          <b>${moment(sel('_.store_sales_period.sell_start_at', prj)).format('YY.MM.DD')}</b>
        </td>
      </tr>
      <tr
        class="store_sales_period ${new Date(sel('_.store_sales_period.sell_end_at', prj)) < new Date()
          ? 'red'
          : ''}"
      >
        <th>판매종료</th>
        <td colspan="3">
          <b>
            ${moment(sel('_.store_sales_period.sell_end_at', prj)).format('YY.MM.DD')}
            (${moment(sel('_.store_sales_period.sell_end_at', prj)).diff(
              moment(sel('_.store_sales_period.sell_start_at', prj)),
              'days',
            ) + 1}일간)
          </b>
        </td>
      </tr>
      ${prj.is_shopify
        ? html`
            <tr class="store_sales_period ${prj.paid_amount == 0 ? 'red' : ''}">
              <th>결제일</th>
              <td colspan="3">
                <b>
                  ${prj.paid_amount == 0
                    ? '미결제'
                    : moment(sel('_.projection_payments.0.paid_at', prj)).format('YY.MM.DD')}
                </b>
              </td>
            </tr>
          `
        : ''}
      <tr class="store">
        <th>마플샵</th>
        <td colspan="3">
          <a href="https://marpple.shop/kr/${sel('_.store.domain_name', prj)}" target="_blank">
            ${sel('_.store.name', prj)}
          </a>
          ${box.sel('is_user->_->policies->shipping_data_policy') && sel('_.store.owner_mobile', prj)
            ? html` <div>${sel('_.store.owner_mobile', prj)}</div> `
            : ''}
        </td>
      </tr>`;
  } else {
    return html`
      <td class="shop_title" colspan="4" style="background:#FF6B00;color:black">
        <span style="font-size:16px;line-height:20px">
          <b>마플</b>
        </span>
      </td>
    `;
  }
});

const makeShopfreecaPhoneCaseProductFaces2 = (prj) => {
  if (
    prj._?.store?._?.crew?.crew_id == MShopUtilConstantS.SOOP_MARPPLESHOP_CREW_ID &&
    prj.merged_type == 'parent'
  ) {
    const up_cs = go(
      prj._.ups,
      flatMap((up) => up._.up_cs),
      filter(({ _: { base_product } }) => {
        return CateListS.isPhoneCaseByCateItemId(base_product.cate_item_id);
      }),
    );
    if (up_cs.length > 0) {
      return html`
        <div class="shopfreeca-phonecase">
          <button type="button" data-fx-json="${$dataStr({ up_cs })}">폰케이스 기종 갱신</button>
        </div>
      `;
    } else {
      return '';
    }
  } else {
    return '';
  }
};

export const makeDfProjectionItemWithoutTaskHtml = (prj) => {
  const hasNotAutoTaskOrder = go(
    prj._.ups,
    reject((up) => GoodsTypeS.isTpl(up.goods_type_id)),
    flatMap((up) => up._.up_cs),
    some((up_c) => !(!!up_c._.base_product.task_set_id || !!up_c._.base_product.is_not_image_task)),
  );

  return html`
    <div class="col prj_summary" master_admin="${!!box.sel('user->_->policies->master_admin')}">
      <div
        class="projection_sidebar"
        _sel="./_->projection->_->projections"
        has_combined="${some((p) => p.type == 'combined', sel('_.projection._.projections'))}"
        merged_type="${prj.merged_type}"
        data-is_partial_delivery="${!!go(prj._.ups, pluck('partial_delivery_at'), compact).length}"
      >
        ${go(
          prj,
          sel('_.projection._.projections'),
          strMap((p) => makeDfProjectionSidebarItemHtml(prj, p)),
        )}
      </div>
    </div>
    <div class="col ship_and_ups">
      <div
        class="cell ups_and_ships"
        is_cancel_req="${prj.is_cancel_req}"
        is_canceled="${prj.is_canceled}"
        merged_type="${prj.merged_type}"
      >
        ${hasNotAutoTaskOrder
          ? legacyHtml`
            <div class="task_stat_noticer">
              수동 할당이 필요한 주문이 존재합니다.
            </div>`
          : ''}
        ${!prj.o_id
          ? ''
          : legacyHtml`
            <div class="warning_message2">
              <span>!</span>
              <a
                href="https://old_df.marpple.com/projections?search_projection_id=${prj.o_id}"
                target="_blank"
                >구 주문서 ID #${prj.o_id}</a
              >
            </div>
          `}
        ${(prj._.user_projection_count || 0) <= 1
          ? ''
          : legacyHtml`
            <div class="warning_message">
              <span>!</span> ${prj._.user.name || '고객'}님은 총 ${prj._.user_projection_count}번
              주문하셨습니다. 이전 주문을 확인해 주세요 :)
            </div>
          `}
        ${!sel('_.user._.projections.0._.task._.worker.name', prj)
          ? ''
          : legacyHtml`
            <div class="warning_message">
              이전 작업자:
              ${go(
                sel('_.user._.projections', prj),
                map((p) => sel('_.task._.worker.name', p) || '이름없음'),
                uniq,
              ).join(', ')}
              ...
            </div>
          `}
        ${prj.special_request
          ? `
        <div class="cell special_request">
          <div class="body">고객 요청 사항 :<br>${UtilS.escape(_escape(prj.special_request || '')).replace(
            /\n/g,
            '<br>',
          )}</div>
        </div>
      `
          : ''}
        ${makeShopfreecaPhoneCaseProductFaces2(prj)}
        <div class="grid up_list" _sel="./_->ups">
          ${box.sel('states->product_mode') != 'bp_stocks' && makeUpItemsHtml(prj)}
        </div>
        <div class="bp_stocks" _sel="./_->bp_stocks">
          ${makeDfProjectionListBpStocksHtml(prj._.bp_stocks)}
        </div>
        <div class="grid shipping_list">
          ${box.sel('is_user->_->policies->shipping_data_policy')
            ? makeDfProjectionShipItemsHtml({ prj })
            : ''}
        </div>
      </div>
    </div>
  `;
};

export const makeDfProjectionItemColsHtml = (prj) => legacyHtml`
  <div class="grid prj_item_inner">
    ${makeDfProjectionItemWithoutTaskHtml(prj)} ${G.df.task.projection.tmpl(prj._.tasks)}
    ${!prj._.projection_payments ? '' : makePrjPaymentInfoHtml(prj)}
  </div>
`;

export const makeDfProjectionItemHtml = (prj) => legacyHtml`
  <div
    class="prj_item grid infi_item"
    prj_id="${prj.id}"
    _sel="./(#${prj.id})"
    merged_type="${prj.merged_type}"
    is_seller_support="${['SUPPORT_SELLER_M_SGN', 'NO_PRINT_NO_PROFIT_M_SGN'].includes(prj.promotion_code)}"
  >
    ${makeDfProjectionItemColsHtml(prj)}
  </div>
`;

export const makeDfProjectionItemsHtml = strMap(makeDfProjectionItemHtml);
