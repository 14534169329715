import onScan from 'onscan.js';
import { parseAztecData } from '../../Holding/F/Function/holding.js';
import { parseQr } from '../../../../PaperProducts/WE/S/Function/qr.js';
import { filterL, flatten, go, take1, zipWithIndexL, join, match, identity, find } from 'fxjs/es';
import { waybillCJgo, waybillCjGoToLgl } from '../../../Waybill/F/Function/waybill_cj_go.js';
import Swal from 'sweetalert2';
import { PaperProductsS } from '../../../../PaperProducts/S/Function/module/PaperProductsS.js';
import axios from 'axios';
import { DfTaskF } from '../../../Task/F/Function/module/DfTaskF.js';
import { DfInhouseF } from '../../../Inhouse/F/Function/module/DfInhouseF.js';
import { isNessFromDfPrj } from '../../../Projection/List/F/ness.js';
import { UtilArrayS } from '../../../../Util/Array/S/Function/module/UtilArrayS.js';
import { UtilAlertF } from '../../../../Util/Alert/F/Function/module/UtilAlertF.js';
import { UtilStringS } from '../../../../Util/String/S/Function/module/UtilStringS.js';
import { WowPressF } from '../../../../WowPress/F/Function/module/WowPressF.js';
import { UtilS } from '../../../../Util/S/Function/module/UtilS.js';

export const parseLabelCode = (input_val) => {
  if (input_val.indexOf('#') === 0) {
    const [scan_projection_id] = parseAztecData(input_val);
    return scan_projection_id;
  } else {
    return input_val;
  }
};

const getPrefixIndex = (prefix_dict, scan_val) => {
  return go(
    zipWithIndexL(prefix_dict),
    filterL(([idx, prefix]) => {
      if (scan_val?.startsWith(prefix)) {
        return String(idx);
      }
    }),
    flatten,
    take1,
    join(''),
  );
};

export const scannerActionSearchEvents = async (sScanned, inquiry_cb) => {
  /* 한글 모드에서 스캔 하는 경우 영문 입력으로 강제 변환 */
  UtilStringS.hasKorean({ input_str: sScanned }) &&
    (sScanned = UtilStringS.convertKrToEnKeyboardInput(sScanned));

  /* 주문서 조회 모드의 single prefix */
  const prefix_dict = ['#', '^%', '^', 'WP', 'wp'];
  const prefix_match_index = getPrefixIndex(prefix_dict, sScanned);

  switch (prefix_match_index) {
    // prefix: #  (가산 내부 라벨 관리 aztec) => 주문 조회
    case 0: {
      const [scan_projection_id] = parseAztecData(sScanned);
      return scan_projection_id && (await inquiry_cb({ projection_id: scan_projection_id }));
    }
    // prefix: ^%  (WE 주문 qr) => 주문 조회
    case 1: {
      return go(sScanned, parseQr, async (qr) => {
        return qr.projection_id && (await inquiry_cb({ projection_id: qr.projection_id }));
      });
    }
    // prefix: ^ (barcode) => 주문 조회
    case 2: {
      return go(sScanned, PaperProductsS.parseTechPackBarcode, async (data) => {
        const projection_id = await match(data)
          .case((d) => d.projection_id)(identity)
          .case((d) => d.up_id)(async (d) => {
            // get projection_id using up_id
            const {
              data: [projection],
            } = await axios.get('/@api/projection/summary', {
              params: {
                up_id: d.up_id,
              },
            });
            return projection.id;
          })
          .else(() => {
            return null;
          });

        if (!projection_id) return window.alert('알 수 없는 바코드입니다.');

        return inquiry_cb({ projection_id });
      });
    }
    // wowpress 주문
    case 3:
    case 4: {
      const wow_order_no = extractWowOrderNo(sScanned);

      if (wow_order_no != null) {
        const user_product = await WowPressF.apiCall.get.getUpFromWowOrder({ wow_order_no });

        if (!UtilS.isEmpty(user_product)) {
          return await inquiry_cb({ projection_id: user_product.projection_id });
        }
      }
      return null;
    }
    default:
      return null;
  }
};

export const scannerActionEvents = (target_el, inquiry_cb) => {
  onScan.attachTo(target_el, {
    onScan: async (sScanned, iQty) => {
      /*
       * 스캔 default event: 조회 모드
       * 조회 모드 데이터 구조
       *    - 구조: PREFIX(#) + scan_data
       *    - 주의사항: SUFFIX 는 사용하지 않음
       *    - WE 의 경우를 대비하여 # 말고도 ^% 인 PREFIX 2 자리도 허용. (앞으로 외주 업체는 이렇게 구분하는 것으로 규약도 괜찮아 보임)
       * */
      const scan_search_result = await scannerActionSearchEvents(sScanned, inquiry_cb);

      if (!scan_search_result) {
        //1차 조회 조건에 걸리지 않는다면. 다른 action 분기 (special action)
        switch (sScanned[0]) {
          /*
           * 마플 라벨 스캐너 데이터 구조
           *    PREFIX <DATA> SUFFIX1 (SUFFIX 2도 쓸지는 고민, 저가형 스캐너에는 SUFFIX 2를 프로그래밍 못함)
           * 규정
           *    PREFIX, SUFFIX1 모두 특수문자를 사용 (한글/영문 오류 방지)
           *    PREFIX 는 ! 인 경우 action mode
           *    SUFFIX 1 조합으로 action 처리 분기 (약 10 가지 가능)
           * 예시
           *    waybill : !{projection}@
           * */
          case '!': {
            /* 0개 이상의 특수 문자 {prefix 특수문자 명령} - 1개 이상의 숫자 {projection_id} - "그 다음에 오는 첫번째 특수문자 추출" */
            // const suffix_command = getSingleSuffixAfterProjectionNo(sScanned);
            const suffix_command = sScanned.substr(-1);
            const scan_data = sScanned.slice(1, -1);

            switch (suffix_command) {
              case '@': {
                // 규칙: !#{projection_id}@
                // 행동: 주문 페이지 조회 -> 운송장 출력
                if (scan_data == null) {
                  window.alert(`스캔 데이터가 존재하지 않습니다. ${scan_data}`);
                } else {
                  const projection_id = await scannerActionSearchEvents(scan_data, inquiry_cb);

                  if (projection_id) {
                    if (box == null || UtilArrayS.isEmNil(box()?.projections)) {
                      throw new Error(`Box 에 주문서가 존재하지 않습니다.`);
                    }
                    const projections = box().projections;
                    const prj = find((p) => p.id === projection_id, projections);

                    // 사내배송이면 -> 운송장 처리 막기, 사내 배송 라벨 출력
                    if (await deliver_task_handler.inhouse({ projection_id })) return;

                    // 태스크 검증 - 배송 태스크 존재, 검수 태스크 완료
                    if (!(await deliver_task_handler.tasksValidation({ projection_id }))) return;

                    // 부분 출고 질의
                    const { is_partial, force } = await deliver_task_handler.inquiryPartialDeliver({ prj });

                    if (is_partial && !force) return;

                    const is_prj_oversea = ['en', 'jp'].includes(prj.lang);
                    const shipping_company = prj._.shippings?.[0]?._?.shipping_company;

                    if (is_prj_oversea) {
                      if (shipping_company && shipping_company.oversea_3pl_company === 'lgl') {
                        // 해외배송 -> CJ : LGL 통해서 운송
                        await waybillCjGoToLgl(prj);
                      } else {
                        $.alert('스캔 운송장 출력이 지원되지 않는 조건입니다.');
                      }
                    } else {
                      // 국내배송 -> CJ : 일반 국내 배송 운송장 출력
                      await waybillCJgo({ projection_id, force });
                    }
                  }
                }
                break;
              }
              case '&': {
                // 규칙: !#{projection_id | wow_order_no }[{parent_task_id}]@
                // 행동: 주문 페이지 조회 -> 태스크 완료
                const projection_id_part = extractProjectionNoFromScannedData(sScanned);

                if (projection_id_part === '#0000') {
                  /* 태스크 스캔 완료 테스트 모드 (스캐너에 어떤 태스크가 설정되어 있는지 확인) */
                  const parent_task_id = getParseBracketValue(sScanned);
                  await DfTaskF.findTaskMatchingParentId({ parent_task_id });
                } else {
                  let projection_id;

                  if (projection_id_part == null) {
                    const wow_order_no = extractWowOrderNo(sScanned);

                    if (wow_order_no != null) {
                      const user_product = await WowPressF.apiCall.get.getUpFromWowOrder({ wow_order_no });

                      if (!UtilS.isEmpty(user_product)) {
                        projection_id = await inquiry_cb({ projection_id: user_product.projection_id });
                      }
                    }
                  } else {
                    projection_id = await scannerActionSearchEvents(
                      projection_id_part || scan_data,
                      inquiry_cb,
                    );
                  }

                  if (projection_id) {
                    const parent_task_id = getParseBracketValue(sScanned);

                    if (parent_task_id == null) {
                      $.alert(`알 수 없는 task id 입니다.`);
                    }
                    await DfTaskF.completeTaskMatchingParentId({ projection_id, parent_task_id });
                  }
                }
                break;
              }

              default: {
                await scannerActionSearchEvents(scan_data, inquiry_cb);
              }
            }

            break;
          }
          default:
            return sScanned;
        }
      } else {
        await Swal.fire({
          position: 'center',
          icon: 'warning',
          backdrop: false,
          title: `<span style="font-size:18px;">현재 보고계신 페이지 입니다.</span>`,
          showConfirmButton: false,
          timer: 1000,
        });
      }
    },
    prefixKeyCodes: [],
    suffixKeyCodes: [],
    avgTimeByChar: 40,
    keyCodeMapper: function (oEvent) {
      if (oEvent.which === 188) return ',';
      if (oEvent.which === 219) return '[';
      if (oEvent.which === 221) return ']';
      // Fall back to the default decoder in all other cases
      return onScan.decodeKeyEvent(oEvent);
    },
    singleScanQty: 1,
    minLength: 5,
    // reactToPaste: true,
  });
};

/**
 * @description 출고 섹션의 모든 태스크들 API 요청
 * @param {number} projection_id
 * @return {Promise<{title: string, status: string}[]>}
 * */
async function getBeforeDeliverSectionTasks({ projection_id }) {
  return (
    await axios.get('/@api/tasks/delivering_tasks', {
      params: { projection_id },
    })
  ).data;
}

export function getParseBracketValue(input, suffixRegExp = '&') {
  /* suffix 다음 [ ] 브라켓 내부에 있는 숫자열, default: Task Id 는 엠퍼센드 */
  const match = new RegExp(`\\[(\\d+)\\]${suffixRegExp}`).exec(input);
  if (match && match.length > 1) {
    return match[1];
  }
}

export function extractProjectionNoFromScannedData(input) {
  const match = /#(\d+)/.exec(input);
  if (match && match.length > 1) {
    return '#' + match[1];
  }
}

function extractWowOrderNo(input) {
  const match_list = /WP\D*\d+/i.exec(input);

  if (match_list && match_list.length > 0) {
    return match_list[0];
  }
}

/**
 *  @description 국내 배송 validation 로직들
 * */
const deliver_task_handler = {
  inhouse: async ({ projection_id }) => {
    const is_ship_to_inhouse = DfInhouseF.stateProjection.gBox.get.isShipToInhouse({
      projection_id,
    });
    if (is_ship_to_inhouse) {
      await DfInhouseF.handleScanProjectionLabel({ projection_id, is_override: false });
    }

    return is_ship_to_inhouse;
  },
  ness: async ({ prj }) => {
    const is_ness = isNessFromDfPrj({ prj });
    if (is_ness) {
      await UtilAlertF.error({
        title: 'NESS 배송 처리 오류',
        timer: 5000,
        msg: '한진 택배로 배송 처리 바랍니다.',
      });
    }
    return is_ness;
  },
  tasksValidation: async ({ projection_id }) => {
    DfInhouseF.lottie_loader.start();
    const tasks_in_deliver_section = await getBeforeDeliverSectionTasks({ projection_id });
    DfInhouseF.lottie_loader.end();

    const has_deliver_task = find((t) => t.title.includes('배송'), tasks_in_deliver_section);
    if (has_deliver_task == null) {
      await UtilAlertF.error({
        title: '태스크 문제 발생',
        timer: 5000,
        msg: '출고 처리할 배송 태스크가 없습니다.',
      });
      DfInhouseF.lottie_loader.end();
      return false;
    }

    const has_inspection_task = find((t) => /검수/.test(t.title), tasks_in_deliver_section);

    if (has_inspection_task != null && has_inspection_task.status !== 'completed') {
      await UtilAlertF.error({
        title: `${has_inspection_task.title} 미처리`,
        msg: '운송장 출력 전<br>검수 태스크를 먼저 완료시켜 주세요.',
        timer: 5000,
      });
      DfInhouseF.lottie_loader.end();
      return false;
    }

    return true;
  },
  inquiryPartialDeliver: async ({ prj }) => {
    if (prj.status === 'printing') {
      const response = await Swal.fire({
        title: '부분 출고 확인',
        html: '주문서가 제작중 상태입니다.<br>부분 출고를 위해 운송장을 발급하시겠어요?',
        showConfirmButton: true,
        confirmButtonText: '부분출고 진행',
        showCancelButton: true,
        cancelButtonText: '중단하기',
      });
      return { is_partial: true, force: response.isConfirmed };
    }
    return { is_partial: false, force: false };
  },
};
