import { go, head, sel } from 'fxjs/es';
import axios from 'axios';
import Swal from 'sweetalert2';
import { format, formatRelative } from 'date-fns';
import { HANJIN } from '../../../../Shipping/Waybill/S/constant.js';
import { makeDfProjectionListUpdate } from '../../../Projection/List/F/fs.js';
import { DfTaskConstantS } from '../../S/Constant/module/DfTaskConstantS.js';

export function isProjectionNeedWaybillNumber() {
  return _p.go(
    box.sel('task')._.projection._.shippings,
    _p.every((shipping) => ['parcel', 'partition', 'combined'].includes(shipping.type)),
  );
}

export function isDeliveryTask() {
  return box.sel('task').title.indexOf('배송') !== -1;
}

export function isDeliveryCompleted() {
  return isDeliveryTask() && box.sel('task').status === 'completed';
}

export function isWaybillNumberFilled() {
  return _p.go(
    box.sel('task')._.projection._.shippings,
    _p.every((shipping) => shipping.waybill_number),
  );
}

export function isShippingCompanyHanjin() {
  return _p.go(
    box.sel('task')._.projection._.shippings,
    _p.every((shipping) => shipping.shipping_company_id == HANJIN),
  );
}

async function alertPopup({ icon, title, description, timer }) {
  await Swal.fire({
    position: 'center',
    target: '#body',
    icon,
    backdrop: true,
    title: `<span style="font-size:1.5rem; color:black">${title}</span>`,
    html: description,
    showConfirmButton: false,
    timerProgressBar: true,
    timer,
  });
}

export async function findTaskMatchingParentId({ parent_task_id }) {
  if (parent_task_id == null) {
    await alertPopup({
      icon: 'warning',
      title: `스캐너에 설정된 태스크 처리 명령 없음`,
      timer: 3000,
    });
    return;
  }

  parent_task_id = mappingSimplifiedParentId({ parent_task_id });

  try {
    const { ok, data, reason } = await go(
      axios.get('/@api/tasks/task_matching_parent_id', { params: { parent_task_id } }),
      sel('data'),
    );

    if (ok) {
      await alertPopup({
        icon: 'success',
        title: `[${data.title}]`,
        description: `스캐너에 태스크 완료처리 명령 설정됨`,
        timer: 3000,
      });
    } else {
      await alertPopup({
        icon: 'warning',
        title: `${reason}`,
        timer: 5000,
      });
    }
  } catch (e) {
    console.error('ERROR', e);
    await alertPopup({
      icon: 'error',
      title: `알 수 없는 에러 발생`,
      description: `${e.message}<br> 개발팀에게 문의 부탁드립니다.`,
      timer: 7000,
    });
  }
}

/*
 * @description 1-digit 의 숫자를 parent_task_id 로 mapping 합니다.
 *              잦은 태스크 변경이 발생하는 팀 (ex. 검수) 을 위한 기능입니다.
 *              적용 범위가 넓지 않아 우선 하드 코딩해서 사용합니다.
 * */
function mappingSimplifiedParentId({ parent_task_id }) {
  const PARENT_TASK_ID_MAPPING = {
    1: 3813485, // 컬러애드 - 아크릴 키링
    2: 3661116, // 컬러애드 - 투명 포토카드
    3: 5585677, // 컬러애드 - 아크릴 무드등
    4: 3661076, // 서블리원 - 텀블러
    5: 4161342, // 신우 - 마스킹 테이프
    6: 4699346, // 레이저라이플 - 키캡
  };

  if (parent_task_id.length === 1) {
    const mapped_parent_task_id = PARENT_TASK_ID_MAPPING[parent_task_id];
    if (mapped_parent_task_id == null) {
      throw new Error(`알 수 없는 task id 입니다. ${parent_task_id}`);
    } else {
      return mapped_parent_task_id;
    }
  }
  return parent_task_id;
}

export async function completeTaskMatchingParentId({ projection_id, parent_task_id }) {
  try {
    parent_task_id = mappingSimplifiedParentId({ parent_task_id });

    const { ok, data, code, reason } = await go(
      axios.patch('/@api/tasks/update_task_matching_parent_id', { projection_id, parent_task_id }),
      sel('data'),
    );

    if (ok) {
      makeDfProjectionListUpdate();
      await alertPopup({
        icon: 'success',
        title: `[${data.task.title}]`,
        description: `완료!<br>${format(new Date(), 'HH시 mm분')}`,
        timer: 2000,
      });
    } else {
      if (code === '001') {
        /* 이미 처리 완료됨 */
        await alertPopup({
          icon: 'warning',
          title: `[${data.task.title}]`,
          description: `이미 완료되었어요!<br>${formatRelative(
            new Date(data.task.completed_at),
            new Date(),
          )}`,
          timer: 2000,
        });
      } else {
        await alertPopup({
          icon: 'error',
          title: `[${data.task?.title ?? '알 수 없는 태스크'}]`,
          description: reason,
          timer: 5000,
        });
      }
    }
  } catch (e) {
    console.error('ERROR', e);
    await alertPopup({
      icon: 'error',
      title: `알 수 없는 에러 발생`,
      description: `${e.message}<br> 개발팀에게 문의 부탁드립니다.`,
      timer: 5000,
    });
  }
}

export const changProductPositionStatus = async (
  projection_id,
  press_type_id,
  product_position_status,
  is_cancel,
) => {
  try {
    let result;

    if (press_type_id === '1') {
      result = await go(
        axios.patch('/@api/tasks/change_delivery_import', {
          projection_id,
          is_cancel,
        }),
        sel('data'),
      );
    } else {
      result = await go(
        axios.patch('/@api/tasks/change_product_position_status', {
          projection_id,
          press_type_id,
          product_position_status,
          is_cancel,
        }),
        sel('data'),
      );
    }
    if (!result?.data?.task?.length) {
      await alertPopup({
        icon: 'error',
        title: `${
          product_position_status === DfTaskConstantS.PRODUCT_POSITION_STATUS.IMPORT
            ? '입고'
            : DfTaskConstantS.PRODUCT_POSITION_STATUS.EXPORT_TO_DELIVERY
            ? '배송팀으로 출고'
            : '출고'
        }: [${projection_id}]`,
        description:
          result?.reason || `Projection ID: ${projection_id}<br/>실패!<br>${format(new Date(), 'HH시 mm분')}`,
        timer: 2000,
      });
    }
    if (
      result.code === '200' &&
      (press_type_id === '1' ||
        (press_type_id !== '1' && product_position_status !== DfTaskConstantS.PRODUCT_POSITION_STATUS.IMPORT))
    ) {
      alertPopup({
        icon: 'info',
        title: `긴급 출고 상품`,
        description: `<div class="important-df-scanning-pop">주문번호: ${projection_id}<br/>긴급출고 주문입니다.<br />${format(
          new Date(result.prj.due_at),
          'M월 d일 출고',
        )}</div>`,
        timer: 1000,
      }).then();
    }
    return {
      code: result.code,
      projection_id: go(result?.data?.task, head)?.projection_id,
      due_at: result.code === '200' ? format(new Date(result.prj.due_at), 'M월 d일') : null,
    };
  } catch (e) {
    await alertPopup({
      icon: 'error',
      title: `알 수 없는 에러 발생`,
      description: `${e.message}<br> 개발팀에게 문의 부탁드립니다.`,
      timer: 5000,
    });
  }
};
