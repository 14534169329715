import { html } from 'fxjs/es';
// bp['product_info' + _en] && bp['product_info' + _en].length
/* {product_info, brand_logo, brand_name, brand_description }, */
const bpBrandInfo = ({ brand_logo, brand_name, brand_description }) => html`
  <div class="brand">
    <div class="brand_logo"></div>
    <img src="${G.to_150(brand_logo)}" alt="" />
    <div class="brand_name_en">${brand_name}</div>
    <div class="brand_description_en">${brand_description}</div>
  </div>
`;

export const bpInfo = ({ brand_logo, brand_name, brand_description, product_info }) => html`
  <div class="toggle_cont toggle_cont_img product_info" is_open="true">
    <div class="tg_head">
      <span class="tit">${T('product_detail::Product Info')}</span>
      <span class="btn"></span>
    </div>
    <div class="tg_body">
      <div class="product_info_text">${product_info}</div>
      <div class="product_info_text product_info_text--precautions">
        <p><strong>${T('product_detail::precautions')}</strong></p>
        <ul>
          <li>${T('product_detail::precautions_info')}</li>
        </ul>
      </div>
      ${brand_logo && brand_name && brand_description
        ? bpBrandInfo({ brand_logo, brand_name, brand_description })
        : ''}
    </div>
  </div>
`;
