import Papa from 'papaparse';
import { UtilArrayS } from '../../../../modules/Util/Array/S/Function/module/UtilArrayS.js';
import { html, each, every, go, map, strMap } from 'fxjs/es';
import { UtilStringS } from '../../../../modules/Util/String/S/Function/module/UtilStringS.js';
import { loadImageFromUrl } from '../../../../modules/Canvas/S/util.js';
import axios from 'axios';
import Swal from 'sweetalert2';
import { $append, $el, $find, $qs, $remove } from 'fxdom/es';
import { isEmNil } from '../../../../modules/Util/Array/S/Function/index.js';
import { UtilS } from '../../../../modules/Util/S/Function/module/UtilS.js';

export async function deleteBpLabelHandler({ base_product_id }) {
  try {
    const { is_OK, data, reason } = await deleteBpLabel({ base_product_id });

    await Swal.fire({
      icon: is_OK ? 'success' : 'error',
      width: 640,
      title: `삭제 ${is_OK ? '성공' : '실패'}`,
      html: html`
        <span style="color:${is_OK ? 'green' : 'red'};">
          ${is_OK ? `상품번호: ${base_product_id} / 삭제된 라벨 총 ${data.length} 건` : reason}
        </span>
      `,
    });

    is_OK && handleDeleteButton({ is_remove: true });
  } catch (err) {
    console.error(err);
    $.alert(err);
  }
}

export async function uploadBpLabelHandler(ct, bp_id) {
  try {
    const file = ct.files[0];
    if (file == null) return;

    const labels = await parseCsvForBpLabel({ file });

    console.log('labels', labels);

    if (labels.find((label) => UtilS.isEmpty(label.bp_id))) {
      throw new Error(
        'bp_id 상품번호가 존재하지 않는 데이터가 존재합니다.\n파일 내용 중 bp_id 항목이 빠짐없이 기입되어 있는지 확인해 주세요.\nbp_id 는 필수값 입니다.',
      );
    }
    if (labels.find(({ bp_id: bp_id_in_file }) => Number(bp_id_in_file) !== Number(bp_id))) {
      throw new Error(
        `등록 파일의 상품번호가 현재 제품과 다른 데이터가 존재합니다.\n등록 파일 내 모든 bp_id 는 현재 수정하려는 상품번호인 ${bp_id} 와 일치해야 합니다.\n다른 상품의 품표를 등록해서는 안됩니다.`,
      );
    }

    const { is_OK, messages } = await upsertBpLabel({ labels });

    await Swal.fire({
      icon: is_OK ? 'success' : 'error',
      title: `업로드 ${is_OK ? '성공' : '실패'}`,
      width: 800,
      html: html`
        <div style="display:flex;flex-direction:column;gap:4px;align-items:center;">
          ${strMap(({ bp_id, bpc_id, is_OK, msg }) => {
            return html` <div style="display:flex;gap:5px">
              <span>상품번호: (${bp_id})</span>
              ${UtilStringS.isNotEmpty(bpc_id)
                ? html`<span> / 컬러: (${bpc_id}) / </span>`
                : html`<span> / 공통 / </span>`}
              <span style="color:${is_OK ? 'green' : 'red'};">결과: ${msg}</span>
            </div>`;
          }, messages)}
        </div>
      `,
    });

    if (is_OK) {
      handleDeleteButton({ is_remove: false });
      ct.value = '';
    }
  } catch (err) {
    console.error('Error in uploadBpLabelHandler:', err);
    await Swal.fire({
      icon: 'error',
      title: '에러 발생',
      text: err.message || '알 수 없는 오류가 발생했습니다.',
    });
  }
}

function handleDeleteButton({ is_remove }) {
  const control_buttons_el = $qs('.bp_label_register .control_buttons');
  if (control_buttons_el) {
    const delete_button_el = $find('button[name="delete"]', control_buttons_el);
    if (is_remove) {
      delete_button_el && $remove(delete_button_el);
    } else {
      delete_button_el == null &&
        $append($el(html`<button type="button" name="delete"></button>`), control_buttons_el);
    }
  }
}

async function deleteBpLabel({ base_product_id }) {
  return (await axios.delete('/@api/df/base_product_labels/label_data', { params: { base_product_id } }))
    .data;
}

async function upsertBpLabel({ labels }) {
  return (await axios.post('/@api/df/base_product_labels/label_data', labels)).data;
}

function getObjectFromArrays(header, data) {
  if (isEmNil(header) || isEmNil(data)) {
    throw new Error('유효한 배열이 아닙니다.');
  }

  data = data.slice(0, header.length);

  const result = {};
  header.forEach((h, idx) => {
    result[h] = data[idx];
  });
  return result;
}

async function parseCsvForBpLabel({ file }) {
  const labels = [];
  const HEADER = ['key', 'value', 'tag'];
  let parseError = null;

  function labelInit() {
    return { bp_id: null, bpc_id: null, label_data: [] };
  }

  function splitLabelPage() {
    if (label.label_data.length > 0) {
      labels.push(label);
      label = labelInit();
    }
  }

  let label = labelInit();
  return new Promise((res, rej) => {
    Papa.parse(file, {
      step: ({ data: row }) => {
        if (parseError) return; // 에러가 발생했다면 처리 중지

        try {
          if (every((f) => f === '', row)) return;
          if (row[0].toLowerCase() === 'end') return;

          const is_header = UtilArrayS.isSubset(row, HEADER);
          if (is_header) {
            splitLabelPage();
          } else {
            const row_object = getObjectFromArrays(HEADER, row);
            const tag = row_object.tag;
            if (['bp_id', 'bpc_id'].includes(tag)) {
              label[tag] = row_object.value;
              return;
            }
            label.label_data.push(row_object);
          }
        } catch (err) {
          parseError = err; // 에러 발생 시 전역 변수에 저장
        }
      },
      complete: async () => {
        if (parseError) {
          rej(parseError);
          return;
        }

        splitLabelPage();
        try {
          const result = await go(
            labels,
            map(async (label) => {
              const { is_OK, reason } = await validation({ label });
              if (!is_OK) {
                throw new Error(reason);
              }
              return await mutateTemplateToLabel({ label });
            }),
          );
          res(result);
        } catch (err) {
          rej(err);
        }
      },
      error: (err) => {
        rej(err);
      },
    });
  });
}

async function validation({ label }) {
  const IMAGE_SIZE_LIMIT_FOR_BP_LABEL = {
    width: 720, // px
    height: 360, // px
  };
  const { bp_id, label_data } = label;
  const res = { is_OK: true, reason: '' };

  if (!UtilStringS.isNumericString(bp_id)) {
    res.is_OK = false;
    res.reason = `숫자가 아닌 bp_id 가 존재합니다. ${bp_id}`;
  }

  await go(
    label_data,
    each(async (row_object) => {
      const { value, tag } = row_object;
      const tags = tag.split(',').map((t) => UtilStringS.removeSpaces(t));

      if (tags.includes('url')) {
        const url = value;
        const is_valid_url_str = UtilStringS.isValidHttpUrlStr(url);
        if (is_valid_url_str) {
          const { is_OK, reason } = await isValidImageUrl({ url, size_limit: IMAGE_SIZE_LIMIT_FOR_BP_LABEL });
          res.is_OK = is_OK;
          res.reason = reason;
        } else {
          res.is_OK = false;
          res.reason = UtilStringS.isEmNil(url)
            ? `비어 있는 url 이 존재합니다.`
            : `유효하지 않은 url 이 존재합니다. 문제값: ${url}`;
        }
      }
    }),
  );

  return res;
}

async function mutateTemplateToLabel({ label }) {
  const { bp_id, bpc_id, label_data } = label;

  const mutated_label_data = await go(
    label_data,
    map(async (row_object) => {
      const { key, value, tag } = row_object;
      const tags = tag.split(',').map((t) => UtilStringS.removeSpaces(t));
      let result = { k: key, v: value, m: 'text' };

      // EXTEND

      if (tags.includes('title')) {
        result.a = 'block'; // 배치: block
        result.k = '';
        result.f = 'W'; // 폰트굵기: weighted
        result.j = 'C'; // 정렬: center
        result.fh = 16; // 폰트 높이
      }

      if (tags.includes('inline')) {
        result.a = 'inline'; // 배치
      }

      if (tags.includes('block')) {
        result.a = 'block'; // 배치
      }

      if (tags.includes('title_hidden')) {
        result.k = ''; // 필드 타이틀 숨김
      }

      // REPLACE
      if (tags.includes('line')) {
        result = { a: 'line' }; // 배치: 가로선
      }

      if (tags.includes('url')) {
        result.m = 'image';
        result.v = value;
      }
      return result;
    }),
  );

  return { bp_id, bpc_id, label_data: mutated_label_data };
}

async function isValidImageUrl({ url, size_limit: { width, height } }) {
  const res = {
    is_OK: true,
    reason: '',
  };

  try {
    const image = await loadImageFromUrl(url);

    if (image.width > width) {
      res.is_OK = false;
      res.reason = `이미지 가로 px 이 ${width} 를 넘을 수 없습니다.\n${url}`;
    }
    if (image.height > height) {
      res.is_OK = false;
      res.reason = `이미지 세로 px 이 ${height} 를 넘을 수 없습니다.\n${url}`;
    }

    return res;
  } catch (error) {
    return {
      is_OK: false,
      reason: error.message,
    };
  }
}
