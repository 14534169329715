import { html, strMap } from 'fxjs/es';
/*item.url, m_url*/
export const sizeCompare = ({ size_compare_items }, is_mobile) => html`
  <div class="toggle_cont tg_size_compare" is_open="true">
    <div class="tg_head">
      <span class="tit">${T('product_detail::Size Compare')}</span>
      <span class="btn"></span>
    </div>
    <div class="tg_body">
      <div class="size_compare_items swiper-container">
        <div class="swiper-wrapper">
          ${strMap(
            (item) =>
              html`
                <div class="item swiper-slide">
                  <div class="img">
                    <img class="don_lazy" data-src="${(is_mobile ? item.m_url : item.url) || ''}" alt="" />
                  </div>
                  <div class="txt1">${item.txt1}</div>
                  <div class="txt2">${item.txt2}</div>
                </div>
              `,
            size_compare_items,
          )}
        </div>
      </div>
    </div>
  </div>
`;
