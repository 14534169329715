import { filter, find, go, map, pluck, reject } from 'fxjs/es';
import { BpOptionConstantS } from '../Constant/module/BpOptionConstantS.js';
import { BpOptionS } from './module/BpOptionS.js';
import { VectorEditorConstantS } from '../../../VectorEditor/S/Constant/module/VectorEditorConstantS.js';

export const getBpOptionGroupByCategory = ({ bp_option_groups, category }) => {
  return go(
    bp_option_groups,
    find((bpbog) => bpbog.category == category),
  );
};

export const selectBaseProductFaceOptionGroup = ({ base_products_bp_option_groups, bpf_id }) => {
  return go(
    base_products_bp_option_groups,
    filter((bpbog) => bpbog.base_product_face_id == bpf_id),
    map((bpbog) => bpbog._.bp_option_group),
  );
};

export const getBpBogWithoutDosu = (product) => {
  return go(
    product._.base_product._.base_products_bp_option_groups,
    reject((bpbog) =>
      [
        BpOptionConstantS.BP_OPTION_GROUP_DEV_NAME.DOSU_COUNTS,
        BpOptionConstantS.BP_OPTION_GROUP_DEV_NAME.DOSU_COLORS,
      ].includes(bpbog._.bp_option_group?.dev_name),
    ),
  );
};

export const getDefaultBpOptionIds = (product) => {
  return go(getBpBogWithoutDosu(product), pluck('default_bp_option_id'));
};
export const setSelectedOptionGroupWithDefaultBpOptionIds = (product) => {
  product._.selected_option_group = product._.selected_option_group || {};
  product._.selected_option_group._ = product._.selected_option_group._ || {};
  const default_bp_option_ids = BpOptionS.getDefaultBpOptionIds(product);
  if (default_bp_option_ids?.length) {
    product._.selected_option_group.bp_option_ids = default_bp_option_ids;
    // setSelectedOptionGroupBpOptions(product._.selected_option_group);
  } else {
    // console.log('bp_option 이 없는 경우');
    /* bp_options 가 없는 상품이라면 */
    product._.selected_option_group = {};
  }
};

export const selectDosuCountBpOptionGroup = (bp_option_groups) => {
  return go(
    bp_option_groups,
    find(
      (bp_option_group) =>
        bp_option_group.dev_name === BpOptionConstantS.BP_OPTION_GROUP_DEV_NAME.DOSU_COUNTS,
    ),
  );
};

export const selectDosuCountOptionByCount = ({ bp_option_groups, dosu_ctn }) => {
  return go(
    bp_option_groups,
    find(
      (bp_option_group) =>
        bp_option_group.dev_name === BpOptionConstantS.BP_OPTION_GROUP_DEV_NAME.DOSU_COUNTS,
    ),
    (bp_option_group) => bp_option_group._.bp_options,
    find((bp_option) => parseInt(bp_option.dev_name) === dosu_ctn),
  );
};

export const selectDosuColorBpOptionGroup = (bp_option_groups) => {
  return go(
    bp_option_groups,
    find(
      (bp_option_group) =>
        bp_option_group.dev_name === BpOptionConstantS.BP_OPTION_GROUP_DEV_NAME.DOSU_COLORS,
    ),
  );
};

export const getMaxDosuCountInFacesOptionGroups = ({ face_option_groups }) => {
  return go(
    face_option_groups,
    find(
      (bp_option_group) =>
        bp_option_group.dev_name === BpOptionConstantS.BP_OPTION_GROUP_DEV_NAME.DOSU_COUNTS,
    ),
    (bp_option_group) => {
      if (!bp_option_group) throw new Error(`DOSU_COUNTS 이 없습니다.`);
      return bp_option_group._.bp_options;
    },
    map((bp_option) => parseInt(bp_option.dev_name)),
    (arr) => Math.max(...arr),
  );
};

export const getAllBpOptionColorsInFacesOptionGroups = ({ face_option_groups }) => {
  return go(
    face_option_groups,
    find(
      (bp_option_group) =>
        bp_option_group.dev_name === BpOptionConstantS.BP_OPTION_GROUP_DEV_NAME.DOSU_COUNTS,
    ),
    (bp_option_group) => {
      if (!bp_option_group) throw new Error(`DOSU_COUNTS 이 없습니다.`);
      return bp_option_group._.bp_options;
    },
    map((bp_option) => parseInt(bp_option.dev_name)),
    (arr) => Math.max(...arr),
  );
};

export const getBaseProductsListBpOptionGroups = (base_product) => {
  return go(
    base_product._.base_products_bp_option_groups,
    map((bpbog) => bpbog._?.bp_option_group),
    reject((bp_option_group) =>
      [
        BpOptionConstantS.BP_OPTION_GROUP_DEV_NAME.DOSU_COUNTS,
        BpOptionConstantS.BP_OPTION_GROUP_DEV_NAME.DOSU_COLORS,
      ].includes(bp_option_group?.dev_name),
    ),
  );
};

export const hasBpOptionListWithoutVectorEditor = (base_product) => {
  return (
    BpOptionS.getBaseProductsListBpOptionGroups(base_product)?.length &&
    !VectorEditorConstantS.EDITOR_TYPES.includes(base_product.maker_type)
  );
};
